<template>
  <div class="col w-100% h-max bg-white">
    <!-- menu -->
    <Menu
      @activeSplitScreen="
        (url) => {
          isSplitScreen = true;
          SplitScreenUrl = url;
        }
      "
    ></Menu>
    <!-- end menu -->

    <div class="row w-100% flex-1">
      <div
        :class="[isSplitScreen ? 'w-50%' : 'w-100%']"
        class="row flex-1 position-relative"
      >
        <!-- mapa -->
        <div
          id="contenedor-mapa"
          class="w-100% h-100% bg-red position-absolute z-1"
        ></div>
        <!-- end mapa -->

        <!-- buscador y filtros -->
        <div class="row w-100% position-absolute top-0 left-0 z-2">
          <div
            class="sm:col-12 ph-3"
            :class="[isSplitScreen ? 'col-12' : 'md:row md:w-fit-content']"
          >
            <!-- buscador -->
            <BuscadorMunicipioComunidad
              @selectItem="_requestItemBuscador"
              ref="refBuscadorMunicipioComunidad"
              @back="
                ItemFiltroSelected = ItemFiltroSelectedAux;
                $refs.refBuscadorMunicipioComunidad.setShowBack(false);
                _requestItemBuscador(ItemSearchAux);
              "
            ></BuscadorMunicipioComunidad>
          </div>
          <div
            class="sm:col-12"
            :class="[isSplitScreen ? 'col-12' : 'md:flex-1']"
          >
            <!-- filtros -->
            <Filtros
              @selectItem="_requestItemFiltro"
              @selectMarkers="_requestStatusMarkers"
              :ItemSearch="ItemSearch"
              ref="refFiltros"
            ></Filtros>
            <!-- end filtros -->
          </div>
        </div>
        <!-- end buscador y filtros -->

        <div
          class="
            row
            sm:w-100%
            md:w-30%
            position-absolute
            bottom-0
            left-0
            z-1
            p-3
          "
        >
          <MapaDetalle
            ref="refMapaDetalle"
            :ItemFiltroSelected="ItemFiltroSelected"
            :indexColor="_indexColor"
            :indexColorDefault="_indexColorDefault"
          ></MapaDetalle>
        </div>
      </div>

      <div v-if="isSplitScreen" :class="[isSplitScreen ? 'w-50%' : '']">
        <SitiosExternos
          :url="SplitScreenUrl"
          @closePanel="isSplitScreen = false"
        ></SitiosExternos>
      </div>
    </div>

    <WelcomDialogo
      v-if="modalWelcomDialogo"
      @closeModal="modalWelcomDialogo = false"/>
  </div>
</template>

<script>
import Menu from "./components/Menu";
import BuscadorMunicipioComunidad from "./components/BuscadorMunicipioComunidad";
import Filtros from "./components/Filtros";
import SitiosExternos from "./components/SitiosExternos";
import MapaDetalle from "./components/MapaDetalle";
import MapaService from "@/services/mapa.service";
import WelcomDialogo from "./components/WelcomDialogo";
import axios from 'axios';

let me = null;
export default {
  components: {
    Menu,
    BuscadorMunicipioComunidad,
    Filtros,
    SitiosExternos,
    MapaDetalle,
    WelcomDialogo
  },
  data() {
    return {
      //modales
      modalDatosConsolidados: false,
      modalWelcomDialogo: false,
      //mapa
      map: null,
      layerGeoJSON: null,
      poligonoMunicipios: null,
      //datos de coordenaas
      arraCoordenadasComunidades: [],
      arrayMarkers: [],
      layerMarkerComunidades: new L.LayerGroup(),
      //pagina dividida
      isSplitScreen: false,
      SplitScreenUrl: "",
      //Buscar
      ItemSearch: {
        rango: "Bolivia", //Municipio-Comunidad
        codigo: null,
        codigo_ine: null,
        comunidad: null,
        municipio: null,
      },
      ItemSearchAux: {},
      // filtros
      ItemFiltroSelected: {
        data: [],
        title: "ICCOM Municipales - Bolivia",
        subtitle: "",
        columna_filtro: "",
        url_filtro: "/consolidado-ica",
        url_estadistica: "/estadistica-ica",
      },
      ItemFiltroSelectedAux: {},
      //indices de colores
      indexColor:[]
    };
  },
  computed:{
    _indexColor(){
      return this.indexColor;
    },
    _indexColorDefault(){
      return {
        cat_max1:1,
        cat_max2:0.6894,
        cat_max3:0.4844,
        cat_max4:0.3142,
        cat_max5:0.1624,
        cat_min1:0.6895,
        cat_min2:0.4845,
        cat_min3:0.3143,
        cat_min4:0.1625,
        cat_min5:0
      }
    }
  },
  created(){
    me = this;
    //cargamos lo indices de colores
    let url = "./public/assets/indice_colores.json";
    if (process.env.VUE_APP_NODE_ENV == "local") {
      url = "./indice_colores.json";
    }
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        me.GLOBAL.log(data);
        me.indexColor = data;
      });
    //verificamos si el dialogo de opciones se mostro
    let welcome =  JSON.parse(localStorage.getItem('welcome.status'));
    if(!welcome){
      this.modalWelcomDialogo =  true;
    }
    // //login del modelador de datos
    // var username = 'Admin';
    // var password = 'password'

    // const token = `${username}:${password}`;
    // const encodedToken = Buffer.from(token).toString('base64');
    // const session_url = 'http://icadatos.bayoex.com/pentaho';

    // var config = {
    //   method: 'POST',
    //   url: session_url,
    //   headers: { 'Authorization': 'Basic YWRtaW46cGFzc3dvcmQ=' }
    // };

    // axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

    // axios
    //      .post('http://icadatos.bayoex.com/pentaho', {
    //         email: user.user,
    //         password: user.password,
    //         id_ciudad: user.id_ciudad,
    //      })
    //      .then((response) => {
    //         if (response.data.token) {
    //            localStorage.setItem('user', JSON.stringify(response.data));
    //         }
    //         return response.data;
    //      });
  },
  mounted() {
    // var item = "";
    // if (item == null || item == "null") {
    //   console.log("Agrega 0");
    // } else if (typeof item == "number") {
    //   console.log("Agrega " + item);
    // } else if (typeof item == "string") {
    //   item = item.replace(",", ".");
    //   if (!isNaN(item) && item.length > 0) {
    //     //se puede comvertir a numero
    //     console.log("Agrega+ " + parseFloat(item));
    //   }
    // }
    // console.log(
    //   "is:" +
    //     isNaN("5.6") +
    //     "-" +
    //     "5,8".replace(",", ".") +
    //     typeof 4 +
    //     ":" +
    //     typeof "4.5"
    // );
    // return;
    me = this;
    //config de FRAMEWORK GUDi
    me.initScrollHorizontal();
    //analiza datos
    // me.ItemFiltroSelected = {
    //   title: 'ICCOM Municipal - Bolivia',
    //   subtitle: '',
    //   url_filtro: '',
    //   url_estadistica: '/ica-comunidad',
    // }
    me._analisaDatos();
    //iniciamos la carga del mapa leaflet
    me.initMapa();
    //cargamos el json de poligonos por unica vez
    // me.cargaGeoJSON();
  },
  methods: {
    _requestStatusMarkers(isShow) {
      if (isShow == true) {
        if (me.ItemSearch.rango == "Comunidad") {
          me.DBgetComunidades({
            municipio: me.ItemSearch.codigo,
            comunidad: me.ItemSearch.comunidad,
          });
        } else if (me.ItemSearch.rango == "Municipio") {
          me.DBgetComunidades({ municipio: me.ItemSearch.codigo });
        } else {
          // me.DBgetComunidades({});
        }
      } else {
        // limpiamos el layer
        //crea un capa para los markes
        me.layerMarkerComunidades.addTo(app.map);
        //limpiar mapa si no hay errores del servidor
        me.layerMarkerComunidades.clearLayers();
      }
    },
    _requestItemFiltro(item) {
      //validamos si se presiono dibujar comunidades
      // if (item.title != "Comunidades") {
      me.ItemFiltroSelected = item;
      me._analisaDatos();
      // }
    },

    _requestItemBuscador(item) {
      me.ItemSearch = item;
      //resetea los filtros por defecto
      switch (me.ItemSearch.rango) {
        //::::::::::::::::::::::::::::::::
        case "Bolivia":
          me.ItemFiltroSelected = {
            data: [],
            title: "ICCOM Municipales - Bolivia",
            subtitle: "",
            columna_filtro: "",
            url_filtro: "/consolidado-ica",
            url_estadistica: "/estadistica-ica",
          };
          // reseteamos el estado swich markers
          me.$refs.refFiltros.setShowMarker(false);
          //crea un capa para los markes
          me.layerMarkerComunidades.addTo(app.map);
          //limpiar mapa si no hay errores del servidor
          me.layerMarkerComunidades.clearLayers();
          break;

        case "Municipio":
          me.ItemFiltroSelected = {
            data: [],
            title: "ICCOM Municipal - Municipio",
            subtitle: me.ItemSearch.municipio,
            columna_filtro: "",
            url_filtro: "/consolidado-ica",
            url_estadistica: "/estadistica-ica",
          };
          if (me.$refs.refFiltros.isShowMarker()) {
            me._requestStatusMarkers(true);
          }

          break;
        case "Comunidad":
          me.ItemFiltroSelected = {
            data: [],
            title: "ICCOM Municipal - Comunidad",
            subtitle: me.ItemSearch.municipio+" - "+me.ItemSearch.comunidad,
            columna_filtro: "",
            url_filtro: "/coordenadas-comunidades",
            url_estadistica: "/ica-comunidad",
          };
          me.$refs.refFiltros.setShowMarker(true);
          me._requestStatusMarkers(true);
          break;
      }
      me._analisaDatos();
    },
    _analisaDatos() {
      console.clear();
      console.log("ANALIZANDO DATOS: ");
      console.log(me.ItemFiltroSelected);
      console.log(me.ItemSearch);
      // arma la url para la consulta
      var url_filtro = "";
      switch (me.ItemSearch.rango) {
        //::::::::::::::::::::::::::::::::
        case "Bolivia":
          switch (me.ItemFiltroSelected.title) {
            case "ICCOM Municipales - Bolivia":
              //limpiamos los datos cuando se hace una busqueda o seleccion de filtro
              me.$refs.refMapaDetalle.limpiaEstadisticas();
              url_filtro =
                me.ItemFiltroSelected.url_filtro +
                "?time=" +
                me.GLOBAL.generateUUID();
              me.DBgetFiltro(url_filtro);
              break;
            case "ICCOM Disponibilidad Municipales":
            case "ICCOM Acceso Municipales":
            case "ICCOM Uso Municipales":
            case "ICCOM Cultivos Municipales":
              //limpiamos los datos cuando se hace una busqueda o seleccion de filtro
              me.$refs.refMapaDetalle.limpiaEstadisticas();
              url_filtro =
                me.ItemFiltroSelected.url_filtro +
                "?tipo=" +
                me.ItemFiltroSelected.columna_filtro +
                "&time=" +
                me.GLOBAL.generateUUID();
              me.DBgetFiltro(url_filtro);
              break;
          }

          break;
        //::::::::::::::::::::::::::::::::
        case "Municipio":
          switch (me.ItemFiltroSelected.title) {
            case "ICCOM Municipal - Municipio":
              //limpiamos los datos cuando se hace una busqueda o seleccion de filtro
              me.$refs.refMapaDetalle.limpiaEstadisticas();
              //generamos las estadisticas del filtro y comunidad
              me.$refs.refMapaDetalle.generaEstadisticas(
                me.ItemSearch.codigo,
                me.ItemFiltroSelected
              );
              url_filtro =
                me.ItemFiltroSelected.url_filtro +
                "?municipio=" +
                (me.ItemSearch.codigo != null ? me.ItemSearch.codigo : "") +
                "&time=" +
                me.GLOBAL.generateUUID();
              me.DBgetFiltro(url_filtro);
              break;
            case "ICCOM Disponibilidad":
            case "ICCOM Acceso":
            case "ICCOM Uso":
            case "ICCOM Cultivos":
              //limpiamos los datos cuando se hace una busqueda o seleccion de filtro
              me.$refs.refMapaDetalle.limpiaEstadisticas();
              //generamos las estadisticas del filtro y comunidad
              me.$refs.refMapaDetalle.generaEstadisticas(
                me.ItemSearch.codigo,
                me.ItemFiltroSelected
              );
              url_filtro =
                me.ItemFiltroSelected.url_filtro +
                "?tipo=" +
                me.ItemFiltroSelected.columna_filtro +
                "&municipio=" +
                (me.ItemSearch.codigo != null ? me.ItemSearch.codigo : "") +
                "&time=" +
                me.GLOBAL.generateUUID();
              me.DBgetFiltro(url_filtro);
              break;
          }
          if (me.$refs.refFiltros.isShowMarker()) {
            me._requestStatusMarkers(true);
          }
          break;
        //::::::::::::::::::::::::::::::::
        case "Comunidad":
          switch (me.ItemFiltroSelected.title) {
            case "ICCOM Municipal - Comunidad":
              //limpiamos los datos cuando se hace una busqueda o seleccion de filtro
              me.$refs.refMapaDetalle.limpiaEstadisticas();
              me.$refs.refMapaDetalle.generaIndiceMunicipalComunidad(
                me.ItemSearch.codigo_ine,
                me.ItemFiltroSelected
              );
              break;
            case "ICCOM Disponibilidad":
            case "ICCOM Acceso":
            case "ICCOM Uso":
              //limpiamos los datos cuando se hace una busqueda o seleccion de filtro
              me.$refs.refMapaDetalle.limpiaEstadisticas();
              me.$refs.refMapaDetalle.generaIndiceMunicipalComunidad(
                me.ItemSearch.codigo_ine,
                me.ItemFiltroSelected
              );
              break;
            case "ICCOM Cultivos":
              //limpiamos los datos cuando se hace una busqueda o seleccion de filtro
              me.$refs.refMapaDetalle.limpiaEstadisticas();
              me.$refs.refMapaDetalle.generaIndiceMunicipalComunidad(
                me.ItemSearch.codigo_ine,
                me.ItemFiltroSelected
              );
              break;
          }
          break;
      }
    },
    DBgetFiltro(url) {
      console.log("DB RECUPERANDO INDICES: " + url);
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando datos, espere porfavor...",
      });
      //realizamos peticion al servicio
      MapaService.get({
        url: url,
      })
        .then((response) => {
          gudi.loading.clear();
          me.ItemFiltroSelected.data = response.data;
          me.cargaGeoJSON();
        })
        .catch(function (err) {
          gudi.loading.clear();
          console.log(err);
        });
    },
    DBgetComunidades({ municipio = "", comunidad = "" }) {
      let url =
        "coordenadas-comunidades?municipio=" +
        municipio +
        "&comunidad=" +
        comunidad +
        "&time=" +
        me.GLOBAL.generateUUID();
      console.log("DB RECUPERANDO coordenadas COMUNIDADES: " + url);
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando coordenadas, espere porfavor...",
      });
      //realizamos peticion al servicio
      MapaService.get({
        url: url,
      })
        .then((response) => {
          gudi.loading.clear();
          me.arraCoordenadasComunidades = response.data;
          me.cargaMarkersComunidad();
        })
        .catch(function (err) {
          gudi.loading.clear();
          console.log(err);
        });
    },
    iconMarkerSelect(item) {
      let rangoActual = 0;
      // if (me.ItemSearch.rango == "Municipio") {
      //determinamos el rango del indice para seleccionar un color
      switch (me.ItemFiltroSelected.title) {
        case "ICCOM Municipal":
          rangoActual = parseFloat(item.indice_ica.replace(",", "."));
          console.log("ica municpal :" + rangoActual);
          break;
        case "ICCOM Disponibilidad":
          rangoActual = parseFloat(
            item.indice_disponibilidad.replace(",", ".")
          );
          console.log("ica Disponibilidad :" + rangoActual);
          break;
        case "ICCOM Acceso":
          rangoActual = parseFloat(item.indice_acceso.replace(",", "."));
          console.log("ica Acceso :" + rangoActual);
          break;
        case "ICCOM Uso":
          rangoActual = parseFloat(item.indice_uso.replace(",", "."));
          console.log("ica Uso :" + rangoActual);
          break;
      }
      // }

      //markers default
      let url = "";
      //asignamos un color de marker deacuerdo al rango del indice
      if (rangoActual >= 1) {
        url =
          process.env.VUE_APP_NODE_ENV == "local"
            ? "./marker/muy_alta.png"
            : "./public/assets/marker/muy_alta.png";
      } else if (rangoActual >= 0.8) {
        url =
          process.env.VUE_APP_NODE_ENV == "local"
            ? "./marker/alta.png"
            : "./public/assets/marker/alta.png";
      } else if (rangoActual >= 0.6) {
        url =
          process.env.VUE_APP_NODE_ENV == "local"
            ? "./marker/media.png"
            : "./public/assets/marker/media.png";
      } else if (rangoActual >= 0.4) {
        url =
          process.env.VUE_APP_NODE_ENV == "local"
            ? "./marker/baja.png"
            : "./public/assets/marker/baja.png";
      } else if (rangoActual >= 0.2) {
        url =
          process.env.VUE_APP_NODE_ENV == "local"
            ? "./marker/muy_baja.png"
            : "./public/assets/marker/muy_baja.png";
      } else {
        url =
          process.env.VUE_APP_NODE_ENV == "local"
            ? "./marker/default.png"
            : "./public/assets/marker/default.png";
      }

      // console.log(url);
      return L.icon({
        iconUrl: url,
        iconSize: [25, 25],
      });
    },
    onClickMarker(item, e) {
      //recuperamos los parametros del marker
      // hacemos zoom
      if (app.map.getZoom() < 8) {
        // app.map.flyTo([parseFloat(e.latlng.lat), parseFloat(e.latlng.lng)], 8);
        app.map.flyTo([parseFloat(item.latitud), parseFloat(item.longitud)], 8);
      }
      // app.map.doubleClickZoom.disable();
      // if (me.ItemSearch.rango == "Municipio") {
      //   me._requestItemBuscador({
      //     codigo: item.codigo,
      //     municipio: item.municipio,
      //     comunidad: item.comunidad,
      //     rango: "Comunidad",
      //   });
      // }
    },
    onDoubleClickMarker(item, event) {
      if (me.ItemSearch.rango == "Municipio") {
        me.ItemFiltroSelectedAux = me.ItemFiltroSelected;
        me.ItemSearchAux = me.ItemSearch;
        me.$refs.refBuscadorMunicipioComunidad.setShowBack(true);
        me._requestItemBuscador({
          codigo: item.codigo,
          municipio: item.municipio,
          codigo_ine: item.codigo_ine,
          comunidad: item.comunidad,
          rango: "Comunidad",
        });
      }
      app.map.doubleClickZoom.disable();
      return false;
      // event.stopPropagation();
    },
    cargaMarkersComunidad() {
      //crea un capa para los markes
      me.layerMarkerComunidades.addTo(app.map);
      //limpiar mapa si no hay errores del servidor
      me.layerMarkerComunidades.clearLayers();
      me.arrayMarkers = [];
      var _items = me.arraCoordenadasComunidades;
      for (var i = 0; i < _items.length; i++) {
        //cramos el marker con la latitud y longitud
        var marker = L.marker(
          [parseFloat(_items[i].latitud), parseFloat(_items[i].longitud)],
          { markerPosition: i, icon: me.iconMarkerSelect(_items[i]) } //parametros
        );
        //creamos el cuerpo para el popup del marker
        const popupContent =
          "<div class='col align-center justify-center'>" +
          "<h6 class='w-100% text-primary-1 text-size-2 text-center mt-2'>" +
          _items[i].codigo_ine +
          " </h6>" +
          "<h1 class='w-100% text-dark bold text-size-2 text-center mt-2 mb-2'>" +
          _items[i].comunidad +
          " </h1>" +
          "<i class='w-100% text-dark-2 text-size-2 text-center mb-2'>" +
          "Doble click para ver detalles";
        " </i>" + "</div>";

        marker.bindPopup(popupContent).openPopup();
        marker.on("click", me.onClickMarker.bind(null, _items[i]));
        marker.on("dblclick", me.onDoubleClickMarker.bind(null, _items[i]));
        //agregarmos el marker al mapa renderizado + el evento click
        me.layerMarkerComunidades.addLayer(marker);
        //guardamos el maker en un pop up
        me.arrayMarkers.push(marker);
      }
      if (_items.length <= 1) {
        me.arrayMarkers[0].openPopup();
        app.map.flyTo(
          [parseFloat(_items[0].latitud), parseFloat(_items[0].longitud)],
          8
        );
      }
    },
    cargaGeoJSON() {
      gudi.loading.show({
        message: "Cargando json poligonos...",
      });
      console.log("CARGA DE JSON POLIGONO");
      let url = "./public/assets/municipios_lite.geojson";
      if (process.env.VUE_APP_NODE_ENV == "local") {
        url = "./municipios_lite.geojson";
      }
      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          gudi.loading.clear();
          me.poligonoMunicipios = data;
          //eliminamos los renderizados anteriores
          if (me.layerGeoJSON != null) {
            app.map.removeLayer(me.layerGeoJSON);
          }

          //iniciamos el nuevo renderizado
          me.renderizaPoligonoMapa();
        });
    },
    renderizaPoligonoMapa() {
      console.log("CARGA DE PILOGONOS MAPA...");
      gudi.loading.show({
        message: "Renderizando poligonos...",
      });

      //creamoa la capa de renderizado
      me.layerGeoJSON = L.geoJSON(me.poligonoMunicipios, {
        style: (feature) => {
          return {
            fillColor:
              me.ItemFiltroSelected.title == "ICCOM Cultivos" ||
              me.ItemFiltroSelected.title == "ICCOM Cultivos Municipales"
                ? "#a2ddff57"
                : me.generarNuevoColor(feature),
            fillOpacity: 0.35,
            opacity: 0.8,
            color: "#000",
            weight: 0.5,
            dashArray: "1", //PUNTEADAS DE TRAZOS DE LINEA
          };
        },
        onEachFeature: function (feature, layer) {
          var municipioCodigo = feature.properties.Name;
          var filteredKeywords = me.ItemFiltroSelected.data.filter((item) => {
            return item.codigo !== null && item.codigo.match(municipioCodigo);
          });
          if (filteredKeywords.length > 0) {
            layer.bindPopup(feature.properties.description);
            //hacer zoom cuando sea solo un municipio o comunidad
            if (me.ItemSearch.rango != "Bolivia") {
              app.map.fitBounds(layer.getBounds());
              // app.map.setCenter(layer.getBounds());
              // app.map.setZoom(7);
            }
            layer.on("click", me.clickMapa.bind(null, feature.properties.Name));
            // console.log(feature.properties.Name + "=060201");
            if ((feature.properties.Name = "060201")) {
              // loading.loaderEnd();
              gudi.loading.clear();
            }
            layer.myTag = "myGeoJSON";
            //mouse
            layer.on("mouseover", function () {
              this.setStyle({
                fillOpacity: 0.55,
              });
            });
            layer.on("mouseout", function () {
              this.setStyle({
                fillOpacity: 0.35,
              });
            });
          }
        },
      }).addTo(app.map);
      //hacer zoom caundo solo sea bolia
      if (me.ItemSearch.rango == "Bolivia") {
        app.map.fitBounds(me.layerGeoJSON.getBounds());
        // app.map.setZoom(6);
      }
    },
    clickMapa(codigo, event) {
      // app.map.fitBounds(event.target.getBounds());
      // app.map.setZoom(7);
      // console.log(codigo);
      // console.log(event);
      if (me.ItemSearch.rango != "Comunidad") {
        me.$refs.refMapaDetalle.generaEstadisticas(
          codigo,
          me.ItemFiltroSelected
        );
      }
    },
    generarNuevoColor(municipio) {
      var arrayColores = [
        "#a80000",
        "#ff0000",
        "#e69800",
        "#ffff00",
        "#4ce600",
      ];

      // return arrayColores[me.GLOBAL.random(0, arrayColores.length - 1)];
      var color = "";
      var municipioCodigo = municipio.properties.Name;

      var filteredKeywords = me.ItemFiltroSelected.data.find((item) => {
        return item.codigo !== null && item.codigo.match(municipioCodigo);
      });

      // console.log(municipioCodigo);
      // console.log(filteredKeywords[0]);

      if (filteredKeywords) {
        if (filteredKeywords.rango == null) {
          filteredKeywords.rango = "0";
        }
        var rango = parseFloat(filteredKeywords.rango.replace(",", "."));
        // console.log("rango: " + rango);
        if (
          me.ItemFiltroSelected.title == "ICCOM Cultivos Municipales" ||
          me.ItemFiltroSelected.title == "ICCOM Cultivos"
        ) {
          console.log("rangos normales");
          if (rango <= 0) {
            color = arrayColores[1]; //naranja rojo
          } else if (rango >= 0.001 && rango <= 2) {
            color = arrayColores[4]; //verde
          } else if (rango > 2) {
            color = arrayColores[3]; //amarillo
          }
        } else if("filter" in me.ItemFiltroSelected){
          console.log("rangos con filtro de colores");
          let auxIndice = me.ItemFiltroSelected.filter == "disponibilidad"?me._indexColor.disponibilidad:
                          me.ItemFiltroSelected.filter == "acceso"? me._indexColor.acceso: me._indexColor.uso;
          let _indexColorSelected = auxIndice.find(item=>{
            return item.column == me.ItemFiltroSelected.columna_filtro;
          })

          _indexColorSelected = _indexColorSelected? _indexColorSelected:this._indexColorDefault;

           // rangos para disponibilidad, acceso y uso
          if (rango >= _indexColorSelected.cat_min5 && rango <= _indexColorSelected.cat_max5) {
            color = arrayColores[4]; //amarillo
          } else if (rango >= _indexColorSelected.cat_min4 && rango <= _indexColorSelected.cat_max4) {
            color = arrayColores[3]; //naranja
          } else if (rango >= _indexColorSelected.cat_min3 && rango <= _indexColorSelected.cat_max3) {
            color = arrayColores[2]; //naranja rojo
          } else if (rango >= _indexColorSelected.cat_min2 && rango <= _indexColorSelected.cat_max2) {
            color = arrayColores[1]; //naranja rojo
          } else if (rango >= _indexColorSelected.cat_min1 && rango <= _indexColorSelected.cat_max1) {
            color = arrayColores[0]; //rojo
          }
        } else {
          console.log("rangos generales");
          //obtenemos la columna del filtro para saber a que categoria pertence y asi obtenes el array de valores
          let auxIndice = me.ItemFiltroSelected.columna_filtro == "disponibilidad"?me._indexColor.disponibilidad:
                          me.ItemFiltroSelected.columna_filtro == "acceso"? me._indexColor.acceso:
                          me.ItemFiltroSelected.columna_filtro == "uso"? me._indexColor.uso:me._indexColor.general;
                          console.log(auxIndice);
          let _indexColorSelected = auxIndice.find(item=>{
            return item.column == "sumatoria";
          })

          _indexColorSelected = _indexColorSelected? _indexColorSelected:this._indexColorDefault;

           // rangos para disponibilidad, acceso y uso
          if (rango >= _indexColorSelected.cat_min5 && rango <= _indexColorSelected.cat_max5) {
            color = arrayColores[4]; //amarillo
          } else if (rango >= _indexColorSelected.cat_min4 && rango <= _indexColorSelected.cat_max4) {
            color = arrayColores[3]; //naranja
          } else if (rango >= _indexColorSelected.cat_min3 && rango <= _indexColorSelected.cat_max3) {
            color = arrayColores[2]; //naranja rojo
          } else if (rango >= _indexColorSelected.cat_min2 && rango <= _indexColorSelected.cat_max2) {
            color = arrayColores[1]; //naranja rojo
          } else if (rango >= _indexColorSelected.cat_min1 && rango <= _indexColorSelected.cat_max1) {
            color = arrayColores[0]; //rojo
          }
        }
      } else {
        color = "#2fbcff69";
      }
      return color;
    },
    initMapa() {
      if (app.map != null) {
        app.map.remove();
      }
      app.map = L.map("contenedor-mapa").setView([-16.573009, -64.663147], 5);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(app.map);

      app.map.zoomControl.setPosition("bottomright");
    },
    initScrollHorizontal() {
      //obtenemos todos los elementos con class scroll-horizontal
      let scrollContainer2 =
        document.getElementsByClassName("scroll-horizontal");
      //iteramos en cada uno de los scroll-horizontales
      for (var i = 0; i < scrollContainer2.length; i++) {
        //obtenemos el item seleccionado
        let divScroll = scrollContainer2[i];
        //agregamos el evento weel para detectar el scroll del elemento
        divScroll.addEventListener("wheel", (event) => {
          //detectamos la direccion del scroll
          const delta = Math.sign(event.deltaY);
          //movemos el scroll
          if (delta < 0) {
            divScroll.scrollLeft -= 30;
          } else {
            divScroll.scrollLeft += 30;
          }
        });
      }
    },
  },
};
</script>

<style>
:root {
   /* colores basicos de textos */
   --color-1: rgb(168, 0, 0);
   --color-2: rgb(255, 0, 0);
   --color-3: rgb(230, 152, 0);
   --color-4: rgb(255, 255, 0);
   --color-5: rgb(76, 230, 0);
}
</style>
