import HTTP from './AppConfig.js';

export default {
   listMunicipios() {
      return HTTP.send('GET', '/documento-lista-municipio?');
   },
   create({ form }) {
      return HTTP.sendForm('POST', '/docuemnto-crear', form);
   },
   list({ codigoMunicipio = 0 }) {
      return HTTP.send('GET', '/documentos-lista/' + codigoMunicipio + '?');
   },
   delete({ id_documento }) {
      return HTTP.send('GET', '/docuemnto-eliminar?id_documento=' + id_documento);
   },
};
// Vue.prototype.$axios = axios
