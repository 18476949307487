<template>
    <canvas id="myChart" class="w-20 h-20"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';
let me= null;
let myChart = null;
export default {
    props:{
        dataRequest:{
            type: Array,
            required: true,
        }
    },
    components: {
    },
    mounted(){
        me=this;
        this.$nextTick(()=>{
            console.log("Dashboard year");
            console.log(me.dataRequest);
            if(me.dataRequest.length<=0){
               myChart.clear() 
               return;
            }
            const ctx = document.getElementById('myChart');
            myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: me.buildLabels(),//['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                    datasets: [{
                        // label: '# of Votes',
                        data: me.buildDataSets(),
                        backgroundColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        // borderColor: [
                        //     'rgba(255, 99, 132, 1)',
                        //     'rgba(54, 162, 235, 1)',
                        //     'rgba(255, 206, 86, 1)',
                        //     'rgba(75, 192, 192, 1)',
                        //     'rgba(153, 102, 255, 1)',
                        //     'rgba(255, 159, 64, 1)'
                        // ],
                        // borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        }); 
    },
    methods:{
        buildLabels(){
            const months=['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec']
            return months;
        },
        buildDataSets(){
            let monthsDataSet=[0,0,0,0,0,0,0,0,0,0,0,0]
            this.dataRequest.forEach(item=>{
                console.log(item.count);
                monthsDataSet[parseInt(item.month)-1] = item.count;
            })
            return monthsDataSet;
        },
        generateRadomColor(){
            const color = [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ];
            return color[this.generateRandomIntegerInRange(0,color.length)];
        },
        generateRandomIntegerInRange(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    }
}
</script>

<style>

</style>