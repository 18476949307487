<template>
  <!-- modal actualiza -->
  <div
    class="
      us:bg-modal-transparent
      fade-in
      col
      w-100%
      h-100%
      top-0
      left-0
      position-fixed
      z-3
      align-center
      justify-center
    "
  >
    <div class="col w-65% h-65% bg-white col align-center">
      <div class="row w-100% bg-primary align-center us:shadow-input-search">
        <h3 class="text-white-2 flex-1 ph-2 pv-2">Perfil</h3>
        <i
          class="
            icon-cancel
            bg-primary-2
            hover:bg-primary
            text-size-5 text-white-3
            cursor-pointer
            p-1
          "
          @click="$emit('closeModal')"
        ></i>
      </div>
      <div class="row w-100% p-2 bg-white shadow-2">
        <div
          class="w-fit-content border-radius-5 ph-2 pv-2 cursor-pointer"
          :class="[
            tabSeleccionado == 'Mi perfil'
              ? 'text-white bg-primary hover:bg-primary-1'
              : 'text-dark-2 hover:bg-white-2',
          ]"
          @click="tabSeleccionado = 'Mi perfil'"
        >
          Mi perfil
        </div>
        <div
          class="w-fit-content border-radius-5 ph-2 pv-2 cursor-pointer"
          :class="[
            tabSeleccionado == 'Cambiar contraseña'
              ? 'text-white bg-primary hover:bg-primary-1'
              : 'text-dark-2 hover:bg-white-2',
          ]"
          @click="tabSeleccionado = 'Cambiar contraseña'"
        >
          Cambiar contraseña
        </div>
      </div>

      <div class="row flex-1 w-100%" v-if="tabSeleccionado == 'Mi perfil'">
        <div class="col h-100% w-100% pt-10">
          <div class="position-relative flex-1 h-100%">
            <div class="scroll-vertical col align-center pt-3">
              <!-- contenido modal -->
              <img
                v-if="currentUser.avatar != null"
                class="bg-white-3 border-radius-50% w-32 h-32 object-cover"
                :src="
                  GLOBAL.baseUrl(
                    '/public/SDcard/usuario_lite/' + currentUser.avatar
                  )
                "
                alt=""
                loading="lazy"
              />
              <img
                v-else
                class="bg-white-3 border-radius-50% w-32 h-32 object-cover"
                src="@/assets/no_image.jpg"
                alt=""
                loading="lazy"
              />
              <h3
                :class="[
                  currentUser.username.lenght > 0
                    ? 'text-dark-1'
                    : 'text-dark-3',
                ]"
                class="bold text-center pt-3"
              >
                {{
                  currentUser.username.lenght > 0
                    ? currentUser.username
                    : "Sin definir"
                }}
              </h3>
              <p class="text-dark-2 pt-2">
                <strong>Correo electrónico: </strong> {{ currentUser.email }}
              </p>
              <p class="text-dark-2 pt-2">
                <strong>Rol: </strong
                >{{
                  currentUser.superAdmin == true ||
                  currentUser.superAdmin == "true"
                    ? "Administrador"
                    : "Invitado"
                }}
              </p>

              <button
                class="
                  mt-5
                  bg-primary-1
                  text-white
                  border-radius-4
                  p-2
                  hover:bg-primary
                  cursor-pointer
                "
                @click="closeSession()"
              >
                Cerrar sesión
              </button>

              <!-- end contenido modal -->
            </div>
          </div>
        </div>
      </div>

      <div
        class="row flex-1 w-100%"
        v-if="tabSeleccionado == 'Cambiar contraseña'"
      >
        <div class="col h-100% w-100% pt-3">
          <div class="position-relative flex-1 h-100%">
            <div class="scroll-vertical col">
              <!-- contenido modal -->
              <div class="row w-100% ph-3">
                <div
                  class="
                    row
                    sm:w-100%
                    md:w-98
                    text-red
                    border-radius-2
                    mt-2
                    p-2
                    border-solid-1 border-color-red
                  "
                >
                  Tenga cuidado al cambiar su contraseña, una vez cambiada solo
                  se podra revertir contactando ala dministrador del sistema.
                </div>
              </div>

              <div class="col w-100% ph-3 pt-3">
                <p class="text-dark-2 pt-2">Contraseña actual</p>
                <input
                  class="
                    us:holder-dark
                    border-radius-1 border-color-dark-3 border-solid-1
                    sm:w-100%
                    md:w-98
                    text-dark
                    p-2
                  "
                  type="password"
                  v-model="form.passwordActual"
                />

                <p class="text-dark-2 pt-10">Contraseña nueva</p>
                <input
                  class="
                    us:holder-dark
                    border-radius-1 border-color-dark-3 border-solid-1
                    sm:w-100%
                    md:w-98
                    text-dark
                    p-2
                  "
                  type="password"
                  v-model="form.passwordNuevo"
                />

                <p class="text-dark-2 pt-2">Repita la contraseña nueva</p>
                <input
                  class="
                    us:holder-dark
                    border-radius-1 border-color-dark-3 border-solid-1
                    sm:w-100%
                    md:w-98
                    text-dark
                    p-2
                  "
                  type="password"
                  v-model="form.repetirPasswordNuevo"
                />
                <div
                  v-if="message_error.length > 0"
                  class="
                    row
                    sm:w-100%
                    md:w-98
                    text-red
                    border-radius-2
                    mt-2
                    p-2
                    border-solid-1 border-color-red
                  "
                >
                  {{ message_error }}
                </div>

                <div
                  class="
                    mt-2
                    w-fit-content
                    border-radius-3
                    ph-2
                    pv-1
                    cursor-pointer
                    bg-primary
                    text-white
                    hover:bg-primary-1
                  "
                  @click="DBupdatePassword()"
                >
                  Guardar cambios
                </div>
              </div>

              <!-- end contenido modal -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsuariosService from "@/services/usuarios.service";

let me = null;
export default {
  data() {
    return {
      tabSeleccionado: "Mi perfil",
      form: {
        passwordActual: "",
        passwordNuevo: "",
        repetirPasswordNuevo: "",
      },
      message_error: "",
    };
  },
  computed: {
    //recuperamos el usuario actual del storage
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    me = this;
  },
  methods: {
    closeSession() {
      gudi.confirm
        .show({
          title: "Cerrar mi sesion",
          message: "Esta seguro de realizar esta accion?",
          textCancel: "Cancelar",
          textSuccess: "Aceptar",
        })
        .then(function (status) {
          if (status) {
            me.$store.dispatch("auth/logout");
            me.$router.replace("/login");
          }
        });
    },
    DBupdatePassword() {
      if (me.form.passwordActual.length <= 3) {
        me.message_error = "La contraseña debe tener más de 4 caracteres.";
        return;
      }
      if (me.form.passwordNuevo.length <= 3) {
        me.message_error =
          "La nueva contraseña debe tener más de 4 caracteres.";
        return;
      }
      if (me.form.passwordNuevo != me.form.repetirPasswordNuevo) {
        me.message_error = "La nueva contraseña no coinciden.";
        return;
      }

      gudi.loading.show({
        message: "Cambiando contraseña...",
      });
      let form = new FormData();

      form.append("id_usuario", me.currentUser.id_usuario);
      form.append("password", me.form.passwordActual);
      form.append("password_new", me.form.passwordNuevo);

      UsuariosService.updatePassword({
        form: form,
      })
        .then((response) => {
          gudi.loading.clear();
          me.message_error = "";
          me.form = {
            passwordActual: "",
            passwordNuevo: "",
            repetirPasswordNuevo: "",
          };
          gudi.alert.show({
            title: "Peticion exitosa",
            message: "Su contraseña se modifico con exito",
          });
        })
        .catch(function (err) {
          gudi.loading.clear();
          gudi.alert.show({
            title: "Error en los datos",
            message: me.GLOBAL.getErrorHTTP(err),
          });
        });
    },
  },
};
</script>

<style>
</style>
