<template>
  <div class="col w-100% pb-5">
    <div class="row w-100% mt-5">
        <select class="us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            text-dark
            p-2" v-model="formIncome.type" @change="changueType">
            <option value="year">Anual</option>
            <option value="month">Mensual</option>
            <option value="week">Semanal</option>
        </select>
        <div class="flex-1 row" v-if="formIncome.type == 'year'">
            <button :disabled ="valueYear<new Date().getFullYear()-2" :class="[valueYear<new Date().getFullYear()-2?'bg-white-1':'bg-white']" @click="valueYear-=1; DBgetVisitasDashboard()" class="bg-white text-primary-1 border-radius-1 shadow-2 ph-5 pv-2 ml-1 hover:bg-white hover:text-primary-1 cursor-pointer">-</button>
            <label class="flex-1 text-center bg-white text-primary-1 border-radius-1 shadow-2 ph-5 pv-2 ml-1 hover:bg-white hover:text-primary-1 cursor-pointer">Gestión {{valueYear}}</label>
            <button :disabled ="valueYear>=new Date().getFullYear()" :class="[valueYear>=new Date().getFullYear()?'bg-white-1':'bg-white']" @click="valueYear+=1; DBgetVisitasDashboard()" class="bg-white text-primary-1 border-radius-1 shadow-2 ph-5 pv-2 ml-1 hover:bg-white hover:text-primary-1 cursor-pointer">+</button>
        </div>
        <div class="flex-1 row" v-if="formIncome.type == 'month'">
            <button :disabled ="valueMonth<=1" @click="valueMonth-=1; DBgetVisitasDashboard()" class="bg-white text-primary-1 border-radius-1 shadow-2 ph-5 pv-2 ml-1 hover:bg-white hover:text-primary-1 cursor-pointer">-</button>
            <label class="flex-1 text-center text-primary-1 border-radius-1 shadow-2 ph-5 pv-2 ml-1 hover:bg-white hover:text-primary-1 cursor-pointer">{{new Date().getFullYear()+' - '+months[valueMonth-1]}}</label>
            <button :disabled ="valueMonth>=12" :class="[valueMonth>=12?'bg-white-1':'bg-white']" @click="valueMonth+=1; DBgetVisitasDashboard()" class="bg-white text-primary-1 border-radius-1 shadow-2 ph-5 pv-2 ml-1 hover:bg-white hover:text-primary-1 cursor-pointer">+</button>
        </div>

        <div class="flex-1 row" v-if="formIncome.type == 'week'">
            <button :disabled ="valueWeek<=1" @click="valueWeek-=1; DBgetVisitasDashboard()" class="bg-white text-primary-1 border-radius-1 shadow-2 ph-5 pv-2 ml-1 hover:bg-white hover:text-primary-1 cursor-pointer">-</button>
            <label class="flex-1 text-center text-primary-1 border-radius-1 shadow-2 ph-5 pv-2 ml-1 hover:bg-white hover:text-primary-1 cursor-pointer">{{new Date().getFullYear()+' - '+months[new Date().getMonth()]}}</label>
            <button :disabled ="valueWeek>=48" :class="[valueWeek>=48?'bg-white-1':'bg-white']" @click="valueWeek+=1; DBgetVisitasDashboard()" class="bg-white text-primary-1 border-radius-1 shadow-2 ph-5 pv-2 ml-1 hover:bg-white hover:text-primary-1 cursor-pointer">+</button>
        </div>
    </div>
    
    <ReportYear v-if="formIncome.type == 'year' && loadedYear" :dataRequest=dataRequest></ReportYear>
    <ReportMonth v-if="formIncome.type == 'month' && loadedMonth" :dataRequest=dataRequest></ReportMonth>
    <ReportWeek v-if="formIncome.type == 'week' && loadedWeek" :dataRequest=dataRequest></ReportWeek>
    <!-- <ReporteVisitasUsuario></ReporteVisitasUsuario> -->
  </div>
</template>

<script>
import ReportMonth from "./ReportMonth.vue"
import ReportYear from "./ReportYear.vue"
import ReportWeek from "./ReportWeek.vue"
import UsuariosService from "@/services/usuarios.service";
let me = null;
export default {
    components:{
        ReportMonth,
        ReportYear,
        ReportWeek
    },
    data(){
        return{
            dataRequest:[],
            formIncome:{
                type : 'year'
            },
            loadedYear:false,
            loadedMonth:false,
            loadedWeek:false,
            valueYear:new Date().getFullYear(),
            valueMonth:new Date().getMonth()+1,
            valueWeek:this.getWeek(),
            months:['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

        }
    },
    mounted(){
        me=this;
        this.DBgetVisitasDashboard();
    },
    methods:{
        getWeek(){
            let currentdate = new Date();
            var oneJan = new Date(currentdate.getFullYear(),0,1);
            var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
            return Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
        },
        changueType(event){
            this.DBgetVisitasDashboard();
        },
        resetLoadedType(){
            me.loadedYear=false;
            me.loadedMonth=false;
            me.loadedWeek=false;
        },
        activeLoadedType(){
            switch(me.formIncome.type){
                case 'year':
                    me.loadedYear = true;
                    break;
                case 'month':
                    me.loadedMonth = true;
                    break;
                case 'week':
                    me.loadedWeek = true;
                    break;
            }
        },
        DBgetVisitasDashboard() {
          //dialogo de carga
          me.resetLoadedType()
          gudi.loading.show({
            message: "Recuperando datos, espere porfavor...",
          });
          
          let parameters={
            type: me.formIncome.type
          }
          switch(me.formIncome.type){
              case 'year':
                   parameters['year'] = me.valueYear;
                  break;
              case 'month':
                   parameters['month'] = me.valueMonth;
                  break;
              case 'week':
                   parameters['week'] = me.valueWeek;
                  break;
          }
         
          //realizamos peticion al servicio
          UsuariosService.incomeControlDashboard({
            parameters:parameters
          }).then((response) => {
                gudi.loading.clear();
                me.dataRequest=response.data
                me.activeLoadedType();
            })
            .catch(function (error) {
                gudi.loading.clear();
                gudi.alert.show({
                    title: "Error en los datos",
                    message: me.GLOBAL.getErrorHTTP(error),
                });
            });
        },
    }
}
</script>

<style>

</style>