<template>
  <!-- modal actualiza -->
  <div
    class="
      us:bg-modal-transparent
      fade-in
      col
      w-100%
      h-100%
      top-0
      left-0
      position-fixed
      z-3
      align-center
      justify-center
    "
  >
    <div class="col md:w-65% md:h-65% sm:h-80% sm:w-90% bg-white col align-center">
      <div class="col w-100% bg-primary align-center us:shadow-input-search">
        <h3 class="text-white-2 flex-1 ph-2 pv-2 bold text-size-6">¡Bienvenido al sistema!</h3>
        <h5 class="text-white p-3 sm:w-90% md:w-50% text-center text-size-4">Analisis integrado de contexto de la vulnerabilidad a la inseguridad alimentaria en Bolivia</h5>
      </div>
      <h5 class="text-primary p-3 w-50% text-center text-size-3">Para continuar selecciona una opción:</h5>
      <div class="row w-90%">
        <div class="sm:col-12 md:col-6 col align-center justify-center us:shadow-input-search border-radius-3 hover:bg-white-1 cursor-pointer">
            <h1 class="text-primary w-60% text-center mt-6 mb-6 sm:text-size-4">ICA MUNICIPAL 2020</h1>
        </div>
        <div class="sm:col-12 md:col-6 col align-center justify-center us:shadow-input-search border-radius-3 hover:bg-white-1 cursor-pointer" @click="changeStatus">
            <h1 class="text-primary w-60% text-center mt-6 mb-6 sm:text-size-4">ICCOM 2021 - 2022</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

let me = null;
export default {
  data() {
    return {
      
    };
  },
  computed: {
   
  },
  mounted() {
    me = this;
  },
  methods: {
    changeStatus(){
        localStorage.setItem('welcome.status', true);
        this.$emit('closeModal');
    }
  },
};
</script>

<style>
</style>
