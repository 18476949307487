<template>
  <div class="col w-100% bg-white border-radius-2 shadow-2">
    <div
      class="row w-100%"
      v-show="isVisibleTable"
      :class="[GLOBAL.countObject(arrayEstadisticas) <= 3 ? 'h-30' : 'h-54']"
    >
      <div class="flex-1 position-relative h-100%">
        <div class="scroll-vertical ph-3">
          <div class="col w-100% pt-3">
            <div
              class="row w-100%"
              v-for="(item, name, index) in arrayEstadisticas"
              :key="index"
            >
              <div
                v-if="
                  ItemFiltroSelected.title == 'ICCOM Cultivos Municipales' ||
                  ItemFiltroSelected.title == 'ICCOM Cultivos'
                "
                class="row w-100% ph-2 align-end"
                style="padding-bottom: 1.5px"
              >
                <div class="p-1 text-size-2">
                  {{ name.replaceAll("_", " ") }}
                </div>
                <div
                  class="flex-1 h-2 border-color-primary"
                  style="border-style: dashed; border-bottom-width: 1px"
                ></div>
                <div class="p-1 text-size-2 text-right">
                  {{ parseFloat(item).toFixed(4) }} ha
                </div>
              </div>

              <div v-else class="col w-100%">
                <p class="text-size-2 text-dark-2 mt-2">
                  {{ name.replaceAll("_", " ") }}: [{{ item.toFixed(4) }}]
                </p>

                <div
                  class="border-radius-2 h-2 mt-1"
                  :class="[
                    item >= 1
                      ? 'p100'
                      : item >= 0.8
                      ? 'p80'
                      : item >= 0.6
                      ? 'p60'
                      : item >= 0.4
                      ? 'p40'
                      : item >= 0.2
                      ? 'p20'
                      : 'p0',
                  ]"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        row
        w-100%
        text-size-2 text-primary
        align-center
        ph-2
        hover:bg-white-1
        cursor-pointer
      "
      :class="[
        isVisibleTable
          ? ''
          : '	border-radius-top-left-2 	border-radius-top-right-2',
      ]"
      @click="isVisibleTable = !isVisibleTable"
      v-if="GLOBAL.countObject(arrayEstadisticas) >= 1"
    >
      <p v-if="isVisibleTable" class="flex-1">Ocultar datos</p>
      <p v-else class="flex-1">Mostrar datos</p>
      <i
        class="icon-back text-size-4 bold"
        :class="[isVisibleTable ? 'rotate-270' : 'rotate-90']"
      ></i>
    </div>
    <div class="row w-100% ph-2 mt-2">
      <div
        v-if="
          ItemFiltroSelected.title == 'ICCOM Cultivos Municipales' ||
          ItemFiltroSelected.title == 'ICCOM Cultivos'
        "
        class="col w-100%"
      >
        <!-- <div class="w-100% ph-2 pv-1 rango-color-20">
          <label class="mb-0">Vulnerabilidad Alta</label>
        </div>
        <div class="w-100% ph-2 pv-1 rango-color-40">
          <label class="mb-0">Vulnerabilidad Media</label>
        </div>
        <div class="w-100% ph-2 pv-1 rango-color-80">
          <label class="mb-0">Vulnerabilidad Baja</label>
        </div> -->
      </div>

      <div v-else class="col w-100% p-2 bg-white-2">
        <div class="w-100% ph-2 pv-1 rango-color-100">
          <label class="mb-0">Muy alta vulnerabilidad</label>
        </div>
        <div class="w-100% ph-2 pv-1 rango-color-80">
          <label class="mb-0">Alta vulnerabilidad</label>
        </div>
        <div class="w-100% ph-2 pv-1 rango-color-60">
          <label class="mb-0">Vulnerabilidad media</label>
        </div>
        <div class="w-100% ph-2 pv-1 rango-color-40">
          <label class="mb-0">Baja vulnerabilidad</label>
        </div>
        <div class="w-100% ph-2 pv-1 rango-color-20">
          <label class="mb-0">Muy baja vulnerabilidad</label>
        </div>
      </div>
    </div>
    <h3 class="bold p-2 text-primary w-100% text-center">
      {{ ItemFiltroSelected.title }}
    </h3>
    <h4
      class="ph-2 pb-2 text-dark-1 w-100% text-center"
      v-if="ItemFiltroSelected.subtitle.length > 0"
    >
      {{ ItemFiltroSelected.subtitle }}
    </h4>
  </div>
</template>

<script>
import MapaService from "@/services/mapa.service";

let me = null;
export default {
  props: ["ItemFiltroSelected", "indexColor", "indexColorDefault"],
  data() {
    return {
      arrayEstadisticas: [],
      isVisibleTable: false,
    };
  },
  computed:{
    _indexColor(){
      return this.indexColor;
    },
    _indexColorDefault(){
      return this.indexColorDefault;
    }
  },

  mounted() {
    me = this;
    // setTimeout(function () {
    //   me.generaEstadisticas();
    // }, 200);
    // console.log(me.ItemFiltroSelected);
    console.log("gg");
    console.log('prueba indices', this.gpAuxIndice);
  },
  methods: {
    limpiaEstadisticas() {
      me.arrayEstadisticas = [];
      me.isVisibleTable = false;
    },
    generaEstadisticas(codigoMunicipio = "", itemFiltro = null) {
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando datos, espere porfavor...",
      });

      //genramos la url a enviarse
      var url =
        itemFiltro.url_estadistica +
        "?codigo_municipio=" +
        codigoMunicipio +
        (me.GLOBAL.isKeyExists(itemFiltro, "subcolumnas")
          ? "&columnas_tabla=" + itemFiltro.subcolumnas
          : "") +
        "&time=" +
        me.GLOBAL.generateUUID();
      console.log("DB GENERAL DETALLE MAPA: " + url);
      //realizamos peticion al servicio
      MapaService.get({
        url: url,
      })
        .then((response) => {
          me.arrayEstadisticas = {};
          //validamos la columna codigo de cada registro
          Object.keys(response.data).forEach((key) => {
            var item = response.data[key];
            //validamos la columna codigo de cada registro
            if (key != "codigo" && key != "codigo_text") {
              if (item == null || item == "null") {
                console.log("Agrega 0");
                Object.assign(me.arrayEstadisticas, {
                  [key]: 0.0,
                });
              } else if (typeof item == "number") {
                console.log("Agrega " + item);
                Object.assign(me.arrayEstadisticas, {
                  [key]: item,
                });
              } else if (typeof item == "string") {
                item = item.replace(",", ".");
                if (!isNaN(item) && item.length > 0) {
                  //se puede comvertir a numero
                  console.log("Agrega+ " + parseFloat(item));
                  Object.assign(me.arrayEstadisticas, {
                    [key]: parseFloat(item),
                  });
                }
              }
            }
            // if (item != null) {
            //   item = item.replace(",", ".");
            //   //verificamos que sea un numero y no un string
            //   if (!isNaN(item) && key != "codigo" && key != "codigo_text") {
            //     //agregamos al array
            //     // me.arrayEstadisticas[key] = parseFloat(item);
            //     Object.assign(me.arrayEstadisticas, {
            //       [key]: parseFloat(item),
            //     });
            //   }
            // } else {
            //   Object.assign(me.arrayEstadisticas, {
            //     [key]: 0.0,
            //   });
            // }
          });
          // console.log(me.arrayEstadisticas);

          gudi.loading.clear();
          me.isVisibleTable = true;
          // me.arrayEstadisticas = response.data;
        })
        .catch(function (err) {
          gudi.loading.clear();
          console.log(err);
        });
    },
    generaIndiceMunicipalComunidad(codigo_ine = "", itemFiltro = null) {
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando datos, espere porfavor...",
      });

      //genramos la url a enviarse
      var url =
        itemFiltro.url_estadistica +
        "?codigo_comunidad=" +
        codigo_ine +
        (me.GLOBAL.isKeyExists(itemFiltro, "subcolumnas")
          ? "&columnas_tabla=" + itemFiltro.subcolumnas
          : "") +
        "&time=" +
        me.GLOBAL.generateUUID();
      console.log("DB GENERAL DETALLE MARKER: " + url);
      //realizamos peticion al servicio
      MapaService.get({
        url: url,
      })
        .then((response) => {
          me.arrayEstadisticas = {};
          //validamos la columna codigo de cada registro
          Object.keys(response.data).forEach((key) => {
            var item = response.data[key];

            //validamos la columna codigo de cada registro
            if (key != "codigo" && key != "codigo_text") {
              if (item == null || item == "null") {
                console.log("Agrega 0");
                Object.assign(me.arrayEstadisticas, {
                  [key]: 0.0,
                });
              } else if (typeof item == "number") {
                console.log("Agrega " + item);
                Object.assign(me.arrayEstadisticas, {
                  [key]: item,
                });
              } else if (typeof item == "string") {
                item = item.replace(",", ".");
                if (!isNaN(item) && item.length > 0) {
                  //se puede comvertir a numero
                  console.log("Agrega+ " + parseFloat(item));
                  Object.assign(me.arrayEstadisticas, {
                    [key]: parseFloat(item),
                  });
                }
              }
            }
            // if (item != null) {
            //   item = item.replace(",", ".");
            //   //verificamos que sea un numero y no un string
            //   if (!isNaN(item) && key != "codigo" && key != "codigo_text") {
            //     //agregamos al array
            //     // me.arrayEstadisticas[key] = parseFloat(item);
            //     Object.assign(me.arrayEstadisticas, {
            //       [key]: parseFloat(item),
            //     });
            //   }
            // } else {
            //   Object.assign(me.arrayEstadisticas, {
            //     [key]: 0.0,
            //   });
            // }
          });
          gudi.loading.clear();
          me.isVisibleTable = true;
        })
        .catch(function (err) {
          gudi.loading.clear();
          console.log(err);
        });
    },
    generaIndiceCultivosComunidad(
      municipio = "",
      comunidad = "",
      itemFiltro = null
    ) {
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando datos, espere porfavor...",
      });

      //genramos la url a enviarse
      var url =
        "ica-cultivos-comunidad?municipio=" +
        municipio +
        "&comunidad=" +
        comunidad +
        (me.GLOBAL.isKeyExists(itemFiltro, "subcolumnas")
          ? "&columnas_tabla=" + itemFiltro.subcolumnas
          : "");
      "&time=" + me.GLOBAL.generateUUID();
      console.log("DB GENERAL DETALLE MARKER: " + url);
      //realizamos peticion al servicio
      MapaService.get({
        url: url,
      })
        .then((response) => {
          me.arrayEstadisticas = {};
          //validamos la columna codigo de cada registro
          Object.keys(response.data).forEach((key) => {
            var item = response.data[key];

            if (key != "codigo" && key != "codigo_text") {
              if (item == null || item == "null") {
                console.log("Agrega 0");
                Object.assign(me.arrayEstadisticas, {
                  [key]: 0.0,
                });
              } else if (typeof item == "number") {
                console.log("Agrega " + item);
                Object.assign(me.arrayEstadisticas, {
                  [key]: item,
                });
              } else if (typeof item == "string") {
                item = item.replace(",", ".");
                if (!isNaN(item) && item.length > 0) {
                  //se puede comvertir a numero
                  console.log("Agrega+ " + parseFloat(item));
                  Object.assign(me.arrayEstadisticas, {
                    [key]: parseFloat(item),
                  });
                }
              }
            }
          });
          gudi.loading.clear();
          me.isVisibleTable = true;
        })
        .catch(function (err) {
          gudi.loading.clear();
          console.log(err);
        });
    },
  },
};
</script>

<style>

.p100 {
  width: 100%;
  background: var(--color-1);
}
.p80 {
  width: 80%;
  background: var(--color-2);
}
.p60 {
  width: 60%;
  background: var(--color-3);
}
.p40 {
  width: 40%;
  background: var(--color-4);
}
.p20 {
  width: 20%;
  background: var(--color-5);
}
.p0 {
  width: 2%;
  background: #858585;
}
.rango-color-100 {
  background: var(--color-1);
  color: white;
  font-size: 12px;
}

.rango-color-80 {
  background: var(--color-2);
  color: white;
  font-size: 12px;
}

.rango-color-60 {
  background: var(--color-3);
  color: white;
  font-size: 12px;
}

.rango-color-40 {
  background: var(--color-4);
  color: rgb(121, 121, 121);
  font-size: 12px;
}

.rango-color-20 {
  background: var(--color-5);
  color: white;
  font-size: 12px;
}
.rango-color-0 {
  background: #858585;
  color: white;
  font-size: 12px;
}
</style>
