<template>
  <!-- modal actualiza -->
  <div
    class="
      us:bg-modal-transparent
      fade-in
      col
      w-100%
      h-100%
      top-0
      left-0
      position-fixed
      z-3
      align-center
      justify-center
    "
  >
    <div class="col w-95% h-95% bg-white col align-center">
      <div
        class="row w-100% bg-primary align-center us:shadow-input-search pv-1"
      >
        <h3 class="text-white-2 flex-1 ph-2 pv-2">Modelador de datos</h3>
        <a
          href="http://icadatos.bayoex.com/pentaho"
          target="_blank"
          class="
            bg-primary-2
            hover:bg-primary
            text-size-3 text-white-3
            cursor-pointer
            mr-1
            h-100%
            w-fit-content
            p-1
            row
            align-center
            justify-center
          "
          @click="$emit('closeModal')"
          >Abrir sitio externo <i class="pt-1 ml-1 icon-external-link"></i
        ></a>
        <i
          class="
            icon-cancel
            bg-primary-2
            hover:bg-primary
            text-size-5 text-white-3
            cursor-pointer
            h-100%
            p-1
            col
            align-center
            justify-center
          "
          @click="$emit('closeModal')"
        ></i>
      </div>

      <div class="row flex-1 w-100%">
        <iframe
          class="w-100% h-100%"
          id="contenedor_frame"
          src="http://icadatos.bayoex.com/pentaho"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
let me = null;
export default {
  data() {
    return {
      tabSeleccionado: "Documentos de comunidades",
      itemsNombres: [
        "Sucre",
        "Yotala",
        "Poroma",
        "Azurduy",
        "Presto",
        "Padilla",
        "Tomilla",
        "El villar",
        "Sopocachi",
      ],
      arrayMunicipios: [],
    };
  },
  mounted() {
    me = this;
    setTimeout(function () {
      me.cargaComunidades();
    }, 200);
  },
  methods: {
    cargaComunidades() {
      for (let index = 0; index < 30; index++) {
        let item = {
          codigo: me.GLOBAL.random(100, 900),
          municipio:
            me.itemsNombres[me.GLOBAL.random(0, me.itemsNombres.length - 1)],
        };
        me.arrayMunicipios.unshift(item);
      }
    },
  },
};
</script>

<style>
</style>
