<template>
  <!-- modal actualiza -->
  <div
    class="
      us:bg-modal-transparent
      fade-in
      col
      w-100%
      h-100%
      top-0
      left-0
      position-fixed
      z-3
      align-center
      justify-center
    "
  >
    <div
      class="
        col
        sm:w-90% sm:h-80%
        md:w-40% md:h-70%
        col
        align-center
        sm:p-2
        md:p-5
      "
    >
      <div class="row w-100% justify-center aling-center mb-3">
        <h3
          class="text-white bold ph-2 pv-1 border-radius-3"
          style="background-color: #00000042"
        >
          Sitios externos
        </h3>
      </div>

      <div
        class="
          bg-primary-1
          text-white
          ph-2
          pv-1
          mb-2
          hover:bg-primary
          cursor-pointer
          border-radius-3
          shadow-2
        "
        v-for="(item, index) in arrayUrls"
        :key="index"
        @click="$emit('selectUrl', item.url)"
      >
        {{ item.etiqueta }}
      </div>

      <div
        @click="$emit('closeModalProveedor')"
        class="
          row
          w-fit-content
          cursor-pointer
          hover:bg-dark
          align-center
          mb-3
          ph-2
          pv-1
          border-radius-3
        "
        style="background-color: #00000042"
      >
        <h3 class="text-white bold">Cancelar</h3>
        <i class="icon-cancel text-white text-size-4 ml-2"></i>
      </div>
    </div>
  </div>
</template>

<script>
let me = null;
export default {
  data() {
    return {
      arrayUrls: {},
    };
  },
  mounted() {
    me = this;
    setTimeout(function () {
      me.cargaSitiosExternos();
    }, 200);
  },
  methods: {
    cargaSitiosExternos() {
      let url = "./public/assets/data-config.json";
      if (process.env.VUE_APP_NODE_ENV == "local") {
        url = "./data-config.json";
      }
      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          me.GLOBAL.log(data);
          me.arrayUrls = data.sitios_externos;
        });
    },
  },
};
</script>

<style>
</style>
