<template>
  <!-- modal actualiza -->
  <div
    class="
      us:bg-modal-transparent
      fade-in
      col
      w-100%
      h-100%
      top-0
      left-0
      position-fixed
      z-3
      align-center
      justify-center
    "
  >
    <div class="col w-95% h-95% bg-white col align-center">
      <div
        class="row w-100% bg-primary align-center us:shadow-input-search pv-1"
      >
        <h3 class="text-white-2 flex-1 ph-2 pv-2">
          Consolidado de datos - Comunidad
        </h3>

        <a
          :href="
            GLOBAL.baseUrl() +
            '/public/SDcard/FichasTecnicas/__' +
            codigo_comunidad +
            '.pdf'
          "
          alt="Descargar"
          class="
            bg-primary-2
            hover:bg-primary
            text-size-3 text-white-3
            cursor-pointer
            mr-1
            h-100%
            w-fit-content
            p-1
            row
            align-center
            justify-center
          "
          ><i class="pt-1 ml-1 icon-pdf text-size-4"></i> Ficha Técnica
        </a>
        <a
          :href="
            GLOBAL.baseUrlApi() +
            '/consolidado-comunidad-xml?codigo_comunidad=' +
            codigo_comunidad
          "
          alt="Descargar"
          class="
            bg-primary-2
            hover:bg-primary
            text-size-3 text-white-3
            cursor-pointer
            mr-1
            h-100%
            w-fit-content
            p-1
            row
            align-center
            justify-center
          "
          ><i class="pt-1 ml-1 icon-exe text-size-4"></i> Generar excel
        </a>
        <i
          class="
            icon-cancel
            bg-primary-2
            hover:bg-primary
            text-size-5 text-white-3
            cursor-pointer
            h-100%
            p-1
            col
            align-center
            justify-center
          "
          @click="$emit('closeModal')"
        ></i>
      </div>

      <!-- lista de registros -->
      <div
        class="flex-1 position-relative h-100% w-100%"
        v-if="arrayTabla != null"
      >
        <div class="scroll-vertical">
          <div class="row w-100% pl-2">
            <img
              src="@/assets/images/min_desarrollo.jpeg"
              class="sm:h-15 md:h-20"
            />
            <div class="flex-1"></div>
            <img
              src="@/assets/images/programa_mundial_de_alimentos.jpeg"
              class="sm:h-15 md:h-20"
            />
          </div>
          <hr class="bg-primary-1 h-1 w-100%" />
          <h5 class="text-dark bold w-100% text-center pt-3">
            ICCOM: ANÁLISIS INTEGRADO DE CONTEXTO DE LA VULNERABILIDAD A LA
            INSEGURIDAD ALIMENTARIA
          </h5>
          <div class="row w-100%">
            <div class="sm:col-12 md:col-8 p-2">
              <div
                class="
                  col
                  w-100%
                  border-radius-2 border-solid-1 border-color-primary
                "
              >
                <h4 class="bold p-2 text-primary w-100%">Resumen</h4>
                <div class="row w-100% ph-2 pv-1 align-end">
                  <div class="text-size-2">Codigo INE</div>
                  <div
                    class="flex-1 h-2 border-color-primary"
                    style="border-style: dashed; border-bottom-width: 1px"
                  ></div>
                  <div class="text-size-2">
                    {{ arrayTabla.informacion_basica.codigo_ine }}
                  </div>
                </div>
                <div class="row w-100% ph-2 pv-1 align-end">
                  <div class="text-size-2">Comunidad</div>
                  <div
                    class="flex-1 h-2 border-color-primary"
                    style="border-style: dashed; border-bottom-width: 1px"
                  ></div>
                  <div class="text-size-2">
                    {{ arrayTabla.informacion_basica.comunidad }}
                  </div>
                </div>
                <div class="row w-100% ph-2 pv-1 align-end">
                  <div class="text-size-2">Municipio</div>
                  <div
                    class="flex-1 h-2 border-color-primary"
                    style="border-style: dashed; border-bottom-width: 1px"
                  ></div>
                  <div class="text-size-2">
                    {{ arrayTabla.informacion_basica.municipio }}
                  </div>
                </div>
                <div class="row w-100% ph-2 pv-1 align-end">
                  <div class="text-size-2">Provincia</div>
                  <div
                    class="flex-1 h-2 border-color-primary"
                    style="border-style: dashed; border-bottom-width: 1px"
                  ></div>
                  <div class="text-size-2">
                    {{ arrayTabla.informacion_basica.provincia }}
                  </div>
                </div>
                <div class="row w-100% ph-2 pv-1 mb-2 align-end">
                  <div class="text-size-2">Departamento</div>
                  <div
                    class="flex-1 h-2 border-color-primary"
                    style="border-style: dashed; border-bottom-width: 1px"
                  ></div>
                  <div class="text-size-2">
                    {{ arrayTabla.informacion_basica.departamento }}
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:col-12 md:col-4 p-2">
              <div
                class="
                  col
                  w-100%
                  h-100%
                  border-radius-2 border-solid-1 border-color-primary
                  p-2
                "
              >
                <h4 class="bold text-primary w-100% pb-3">ICCOM Comunitario</h4>
                <div
                  class="row w-100% ph-2"
                  style="
                    padding-bottom: 1.5px;
                    border-style: solid;
                    border-color: rgb(48, 121, 206);
                    border-width: 1px;
                  "
                  v-for="(item, name, index) in arrayTabla.indices_ica"
                  :key="index"
                  :style="[index % 2 == 0 ? 'background:#dee5e9' : '']"
                >
                  <div class="p-1 text-size-2 bold">
                    {{ index + 1 }}
                  </div>
                  <div
                    class="
                      flex-1
                      p-1
                      text-size-2
                      border-color-primary-1
                      border-solid-1-left
                      border-solid-1-right
                    "
                  >
                    Vulnerabilidad de {{ name.replaceAll("_", " ") }}
                  </div>
                  <div class="p-1 text-size-2 text-right">
                    {{ item.toFixed(4) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:col-12 md:col-4 p-2">
              <div
                class="
                  col
                  w-100%
                  h-100%
                  border-radius-2 border-solid-1 border-color-primary
                "
              >
                <h4 class="bold p-2 text-primary w-100%">ICCOM disponibilidad</h4>
                <div class="position-relative sm:h-50 md:h-80">
                  <div class="scroll-vertical ph-2 pb-2">
                    <div class="row w-100%"  
                      style="
                        padding-bottom: 1.5px;
                        border-style: solid;
                        border-color: rgb(48, 121, 206);
                        border-width: 1px;
                      " >
                      <div class="col-1 p-1 text-size-2 bold">#</div>
                      <div
                        class="
                          flex-1
                          p-1
                          text-size-2
                          border-color-primary-1
                          border-solid-1-left
                          border-solid-1-right
                        ">Nombre</div>
                      <div class="col-2 p-1 text-size-1 text-right">Valor neto</div>
                      <div class="col-2 p-1 text-size-2 text-right">Indice</div>
                    </div>
                    <div
                      class="row w-100%"
                     
                      v-for="(item,index) in customoColumn(arrayTabla.indices_ica_disponibilidad)"
                      :key="index"
                      :style="[index % 2 == 0 ? 'background:#dee5e9' : '']"
                    >
                      <div class="row w-100%"  
                        style="
                          padding-bottom: 1.5px;
                          border-style: solid;
                          border-color: rgb(48, 121, 206);
                          border-width: 1px;
                        " >
                        <div class="col-1 p-1 text-size-2 bold">
                          {{ index + 1 }}
                        </div>
                        <div
                          class="
                            flex-1
                            p-1
                            text-size-2
                            border-color-primary-1
                            border-solid-1-left
                            border-solid-1-right
                          "
                        >
                          {{ item.name }}
                        </div>
                        <div class="col-2 p-1 text-size-2 text-right">
                          {{ item.value_dato}}
                        </div>
                        <div class="col-2 p-1 text-size-2 text-right">
                          {{ item.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:col-12 md:col-4 p-2">
              <div
                class="
                  col
                  w-100%
                  h-100%
                  border-radius-2 border-solid-1 border-color-primary
                "
              >
                <h4 class="bold p-2 text-primary w-100%">ICCOM acceso</h4>
                <div class="position-relative sm:h-50 md:h-80">
                  <div class="scroll-vertical ph-2 pb-2">
                    <div class="row w-100%"  
                      style="
                        padding-bottom: 1.5px;
                        border-style: solid;
                        border-color: rgb(48, 121, 206);
                        border-width: 1px;
                      " >
                      <div class="col-1 p-1 text-size-2 bold">#</div>
                      <div
                        class="
                          flex-1
                          p-1
                          text-size-2
                          border-color-primary-1
                          border-solid-1-left
                          border-solid-1-right
                        ">Nombre</div>
                      <div class="col-2 p-1 text-size-1 text-right">Valor neto</div>
                      <div class="col-2 p-1 text-size-2 text-right">Indice</div>
                    </div>
                    <div
                      class="row w-100%"
                     
                      v-for="(item,index) in customoColumn(arrayTabla.indices_ica_acceso)"
                      :key="index"
                      :style="[index % 2 == 0 ? 'background:#dee5e9' : '']"
                    >
                      <div class="row w-100%"  
                        style="
                          padding-bottom: 1.5px;
                          border-style: solid;
                          border-color: rgb(48, 121, 206);
                          border-width: 1px;
                        " >
                        <div class="col-1 p-1 text-size-2 bold">
                          {{ index + 1 }}
                        </div>
                        <div
                          class="
                            flex-1
                            p-1
                            text-size-2
                            border-color-primary-1
                            border-solid-1-left
                            border-solid-1-right
                          "
                        >
                          {{ item.name }}
                        </div>
                        <div class="col-2 p-1 text-size-2 text-right">
                          {{ item.value_dato}}
                        </div>
                        <div class="col-2 p-1 text-size-2 text-right">
                          {{ item.value }}
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="row w-100% ph-2"
                      style="
                        padding-bottom: 1.5px;
                        border-style: solid;
                        border-color: rgb(48, 121, 206);
                        border-width: 1px;
                      "
                      v-for="(
                        item, name, index
                      ) in arrayTabla.indices_ica_acceso"
                      :key="index"
                      :style="[index % 2 == 0 ? 'background:#dee5e9' : '']"
                    >
                      <div class="col-1 p-1 text-size-2 bold">
                        {{ index + 1 }}
                      </div>
                      <div
                        class="
                          col-9
                          flex-1
                          p-1
                          text-size-2
                          border-color-primary-1
                          border-solid-1-left
                          border-solid-1-right
                        "
                      >
                        {{ name.replaceAll("_", " ") }}
                      </div>
                      <div class="col-2 p-1 text-size-2 text-right">
                        {{ item.toFixed(4) }}
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:col-12 md:col-4 p-2">
              <div
                class="
                  col
                  w-100%
                  h-100%
                  border-radius-2 border-solid-1 border-color-primary
                "
              >
                <h4 class="bold p-2 text-primary w-100%">ICCOM uso</h4>
                <div class="position-relative sm:h-50 md:h-80">
                  <div class="scroll-vertical ph-2 pb-2">
                    <div class="row w-100%"  
                      style="
                        padding-bottom: 1.5px;
                        border-style: solid;
                        border-color: rgb(48, 121, 206);
                        border-width: 1px;
                      " >
                      <div class="col-1 p-1 text-size-2 bold">#</div>
                      <div
                        class="
                          flex-1
                          p-1
                          text-size-2
                          border-color-primary-1
                          border-solid-1-left
                          border-solid-1-right
                        ">Nombre</div>
                      <div class="col-2 p-1 text-size-1 text-right">Valor neto</div>
                      <div class="col-2 p-1 text-size-2 text-right">Indice</div>
                    </div>
                    <div
                      class="row w-100%"
                     
                      v-for="(item,index) in customoColumn(arrayTabla.indices_ica_uso)"
                      :key="index"
                      :style="[index % 2 == 0 ? 'background:#dee5e9' : '']"
                    >
                      <div class="row w-100%"  
                        style="
                          padding-bottom: 1.5px;
                          border-style: solid;
                          border-color: rgb(48, 121, 206);
                          border-width: 1px;
                        " >
                        <div class="col-1 p-1 text-size-2 bold">
                          {{ index + 1 }}
                        </div>
                        <div
                          class="
                            flex-1
                            p-1
                            text-size-2
                            border-color-primary-1
                            border-solid-1-left
                            border-solid-1-right
                          "
                        >
                          {{ item.name }}
                        </div>
                        <div class="col-2 p-1 text-size-2 text-right">
                          {{ item.value_dato}}
                        </div>
                        <div class="col-2 p-1 text-size-2 text-right">
                          {{ item.value }}
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="row w-100% ph-2"
                      style="
                        padding-bottom: 1.5px;
                        border-style: solid;
                        border-color: rgb(48, 121, 206);
                        border-width: 1px;
                      "
                      v-for="(item, name, index) in arrayTabla.indices_ica_uso"
                      :key="index"
                      :style="[index % 2 == 0 ? 'background:#dee5e9' : '']"
                    >
                      <div class="col-1 p-1 text-size-2 bold">
                        {{ index + 1 }}
                      </div>
                      <div
                        class="
                          col-9
                          flex-1
                          p-1
                          text-size-2
                          border-color-primary-1
                          border-solid-1-left
                          border-solid-1-right
                        "
                      >
                        {{ name.replaceAll("_", " ") }}
                      </div>
                      <div class="col-2 p-1 text-size-2 text-right">
                        {{ item.toFixed(4) }}
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div
              class="sm:col-12 md:col-4 p-2"
              v-for="(value, index) in arrayTabla.indices_ica_cultivo"
              :key="index"
            >
              <div
                class="
                  col
                  w-100%
                  h-100%
                  border-radius-2 border-solid-1 border-color-primary
                "
              >
                <h4 class="bold p-2 text-primary w-100%">{{ value.titulo }}</h4>
                <p class="text-primary-2 text-size-2 ph-2 pt-3">
                  Indices indivuales
                </p>
                <div class="position-relative sm:h-50 md:h-80">
                  <div class="scroll-vertical ph-2 pb-2">
                    <div
                      class="row w-100% ph-2"
                      style="
                        padding-bottom: 1.5px;
                        border-style: solid;
                        border-color: rgb(48, 121, 206);
                        border-width: 1px;
                      "
                      v-for="(item, name, index) in value.rango_por_filtros"
                      :key="index"
                      :style="[index % 2 == 0 ? 'background:#dee5e9' : '']"
                    >
                      <div class="col-1 p-1 text-size-2 bold">
                        {{ index + 1 }}
                      </div>
                      <div
                        class="
                          col-8
                          p-1
                          text-size-2
                          border-color-primary-1
                          border-solid-1-left
                          border-solid-1-right
                        "
                      >
                        {{ name.replaceAll("_", " ") }}
                      </div>
                      <div class="col-3 p-1 text-size-2 text-right">
                        {{ parseFloat(item).toFixed(4) }} ha.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end lista de registros -->
    </div>
  </div>
</template>

<script>
import MapaService from "@/services/mapa.service";

let me = null;
export default {
  props: ["codigo_comunidad"],
  data() {
    return {
      arrayTabla: null,
    };
  },
  mounted() {
    me = this;
    setTimeout(function () {
      me.DBgetConsolidado();
    }, 200);
    console.log("validacion", this.validateExtraDato("holas_dato"));
  },
  computed:{
    // customoColumn(){
    //   // let table = this.arrayTabla.indices_ica_acceso.map(item=>{
    //   //   return {'name': item}
    //   // });

    //   return Object.keys(me.arrayTabla.indices_ica_acceso).map(function(key, index) {
    //     //validamos que no exista columnas con el subfijo _dato
    //     console.log("tabla: " + key);
    //     if(me.validateExtraDato(key)==false){
    //       //creamos un nuevo objeto
    //       return {
    //         name: key.replaceAll("_", " "),
    //         value:me.arrayTabla.indices_ica_acceso[key].toFixed(4),
    //         value_dato: (key+'_dato' in me.arrayTabla.indices_ica_acceso)? me.arrayTabla.indices_ica_acceso[key+'_dato'].toFixed(4):'--'
    //       };
    //     }
        
    //   }).filter(item=>item!=undefined);
      
    // }
  },
  methods: {
    customoColumn(valueProcess){
      // let table = this.arrayTabla.indices_ica_acceso.map(item=>{
      //   return {'name': item}
      // });

      return Object.keys(valueProcess).map(function(key, index) {
        //validamos que no exista columnas con el subfijo _dato
        console.log("tabla: " + key);
        if(me.validateExtraDato(key)==false){
          //creamos un nuevo objeto
          return {
            name: key.replaceAll("_", " "),
            value:valueProcess[key].toFixed(4),
            value_dato: (key+'_dato' in valueProcess)? valueProcess[key+'_dato'].toFixed(4):'--'
          };
        }
        
      }).filter(item=>item!=undefined);
      
    },
    validateExtraDato(item){
      let aux = item.split('_');
      if(aux.length>0){
        return aux[aux.length-1]=='dato'? true:false;
      }
      return false;
    },
    DBgetConsolidado() {
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando datos, espere porfavor...",
      });
      //realizamos peticion al servicio
      MapaService.get({
        url: "consolidado-comunidad?codigo_comunidad=" + me.codigo_comunidad,
      })
        .then((response) => {
          gudi.loading.clear();
          me.arrayTabla = response.data;

          me.arrayTabla.indices_ica = me.validaIndicesIca(
            me.arrayTabla.indices_ica
          );
          me.arrayTabla.indices_ica_disponibilidad = me.validaIndicesIca(
            me.arrayTabla.indices_ica_disponibilidad
          );
          me.arrayTabla.indices_ica_acceso = me.validaIndicesIca(
            me.arrayTabla.indices_ica_acceso
          );
          me.arrayTabla.indices_ica_uso = me.validaIndicesIca(
            me.arrayTabla.indices_ica_uso
          );

          me.arrayTabla.indices_ica_cultivo.forEach((element) => {
            element.rango_por_filtros = me.validaIndicesIca(
              element.rango_por_filtros
            );
          });

          console.log(me.arrayTabla.indices_ica_cultivo);
        })
        .catch(function (err) {
          gudi.loading.clear();
          console.log(err);
        });
    },

    validaIndicesIca(arrayIndice) {
      //validamos registros para las estadisticas ica
      let arrayEstadisticas = {};
      //validamos la columna codigo de cada registro
      Object.keys(arrayIndice).forEach((key) => {
        var item = arrayIndice[key];
        if (key != "codigo" && key != "codigo_text") {
          if (item == null || item == "null") {
            console.log("Agrega 0");
            Object.assign(arrayEstadisticas, {
              [key]: 0.0,
            });
          } else if (typeof item == "number") {
            console.log("Agrega " + item);
            Object.assign(arrayEstadisticas, {
              [key]: item,
            });
          } else if (typeof item == "string") {
            item = item.replace(",", ".");
            if (!isNaN(item) && item.length > 0) {
              //se puede comvertir a numero
              console.log("Agrega+ " + parseFloat(item));
              Object.assign(arrayEstadisticas, {
                [key]: parseFloat(item),
              });
            }
          }
        }
      });

      return arrayEstadisticas;
    },
  },
};
</script>

<style>
</style>
