
<template>
  <div class="row w-100% bg-white shadow-2 p-3">
    <!-- titulo -->
    <div class="row w-fit-content justify-center align-center">
      <label
        class="sm:d-block md:d-none text-primary text-size-6"
        onclick="document.getElementById('menu-lateral').classList.toggle('sm:d-none')"
      >
        <i class="fas fa-bars"></i>
      </label>
      <img src="@/assets/images/icon_dark.png" class="w-10 mr-2 sm:ml-2" />
      <h1 class="bold text-primary text-size-5">ICCOM BOLIVIA</h1>
    </div>
    <!-- end titulo -->
    <!-- items -->
    <div
      id="menu-lateral"
      class="
        sm:d-none
        sm:row
        sm:position-fixed
        sm:top-0
        sm:left-0
        sm:z-3
        sm:w-100%
        sm:h-100%
        md:row md:flex-1 md:justify-end md:align-center
        fade-in-left
      "
    >
      <div class="sm:col sm:w-70% sm:bg-white md:row md:w-fit-content">
        <h1 class="md:d-none sm:ml-2 bold text-primary text-size-5 mt-5 mb-5">
          ICCOM PMA
        </h1>
        <h3
          v-if="
            currentUser
          "
          class="
            text-primary text-size-3
            ph-2
            pv-3
            hover:bg-white-2
            cursor-pointer
          "
          @click="modalDocumentos = true"
        >
          <i class="fa-solid fa-folder"></i> Documentos
        </h3>
        <h3
          class="
            text-primary text-size-3
            sm:d-none
            ph-2
            pv-3
            hover:bg-white-2
            cursor-pointer
          "
          @click="modalSitiosExternos = true"
        >
          <i class="fa-solid fa-globe"></i> Sitios externos
        </h3>
        <!-- <h3
          v-if="
            currentUser'
          "
          class="
            text-primary text-size-3
            ph-2
            pv-3
            hover:bg-white-2
            cursor-pointer
          "
          @click="modalModeladorDatos = true"
        >
          <i class="fa-solid fa-database"></i> Modelador de datos
        </h3> -->
        <a
          v-if="
            currentUser
          "
          href="http://icadatos.bayoex.com/pentaho"
          target="_blank"
          class="
            text-primary text-size-3
            ph-2
            pv-3
            hover:bg-white-2
            cursor-pointer
          "
        >
          <i class="fa-solid fa-database"></i> Modelador de datos
        </a>
        <h3
          v-if="
            currentUser
          "
          class="
            text-primary text-size-3
            ph-2
            pv-3
            hover:bg-white-2
            cursor-pointer
          "
          @click="modalUsuariosAdministracion = true"
        >
          <i class="fa-solid fa-users"></i> Adm. Usuarios
        </h3>
        <h3
          v-if="currentUser"
          class="
            text-primary text-size-3
            ph-2
            pv-3
            hover:bg-white-2
            cursor-pointer
          "
          @click="modalPerfil = true"
        >
          <i class="fa-solid fa-id-card-clip"></i> Mi perfil
        </h3>
        <router-link
            v-if="currentUser==false"
            to="/login"
            class="
              text-primary text-size-3
              ph-2
              pv-3
              hover:bg-white-2
              cursor-pointer
            "
          >
            <i class="fa-solid fa-id-card-clip"></i> Iniciar sesion
          </router-link>
      </div>

      <!-- borde negrp transparente que completa el menu -->
      <div
        class="sm:flex-1 md:d-none col bg-dark opacity-5"
        onclick="document.getElementById('menu-lateral').classList.toggle('sm:d-none')"
      ></div>
    </div>
    <!-- end items -->

    <DocumentosModal
      v-if="modalDocumentos"
      @closeModal="modalDocumentos = false"
    ></DocumentosModal>

    <SitiosExternosModal
      v-if="modalSitiosExternos"
      @closeModalProveedor="modalSitiosExternos = false"
      @selectUrl="
        (url) => {
          $emit('activeSplitScreen', url);
          //cerramos modal sitios ext.
          modalSitiosExternos = false;
        }
      "
    ></SitiosExternosModal>

    <ModeladorDatos
      v-if="modalModeladorDatos"
      @closeModal="modalModeladorDatos = false"
    ></ModeladorDatos>

    <UsuariosAdministracion
      v-if="modalUsuariosAdministracion"
      @closeModal="modalUsuariosAdministracion = false"
    ></UsuariosAdministracion>

    <PerfilModal
      v-if="modalPerfil"
      @closeModal="modalPerfil = false"
    ></PerfilModal>
  </div>
</template>

<script>
import SitiosExternosModal from "./SitiosExternosModal";
import UsuariosAdministracion from "./UsuariosAdministracion";
import DocumentosModal from "./DocumentosModal";
import PerfilModal from "./PerfilModal";
import ModeladorDatos from "./ModeladorDatos";
export default {
  components: {
    SitiosExternosModal,
    UsuariosAdministracion,
    DocumentosModal,
    PerfilModal,
    ModeladorDatos,
  },
  data() {
    return {
      modalDocumentos: false,
      modalSitiosExternos: false,
      modalModeladorDatos: false,
      modalUsuariosAdministracion: false,
      modalPerfil: false,
    };
  },
  computed: {
    //recuperamos el usuario actual del storage
    currentUser() {
      let USER = this.$store.state.auth.user;
      if(USER!=null){
        if (USER.superAdmin == true || USER.superAdmin == 'true') {
          return true;
        }
      }
      
      return false;
    },
  },
  mounted() {
    // console.log("ruta:" + this.$route.path);
  },
};
</script>

<style>
</style>
