<template>
  <button
    v-if="statusBack"
    class="
      bg-primary
      text-white
      hover:bg-primary-1
      pl-2
      pr-3
      mr-1
      mt-2
      border-radius-5
      cursor-pointer
      row
      align-center
    "
    @click="$emit('back')"
  >
    <span class="icon-back text-size-5 bold"></span> Volver al Municipio
  </button>
  <div
    v-else
    class="col sm:w-100% md:w-80 bg-white mt-3 border-radius-2 shadow pt-2"
    @focus="showPopup"
    @blur="hidePopup"
    tabindex="-1"
  >
    <div class="row w-100% us:shadow-input-search align-center pb-2 ph-2">
      <input
        class="flex-1 text-size-3 pv-1 text-dark-1"
        type="text"
        ref="refBuscar"
        placeholder="Busca comunidad o municipio..."
        v-model="txtBuscar"
        v-on:keydown="buscar()"
        v-on:keyup.enter="
          clearTimeout(timeoutSearch);
          DBgetProductos();
        "
        @blur="showPopup"
      />
      <i
        v-if="txtBuscar != ''"
        class="
          fa-solid fa-xmark
          text-dark-3 text-size-3
          pv-1
          ph-2
          cursor-pointer
          hover:bg-white-3 hover:text-white
          border-radius-5
        "
        @click="
          buscar();
          txtBuscar = '';
          seleccionaItem(null);
        "
      ></i>
      <i
        v-if="txtBuscar == ''"
        class="fa-solid fa-magnifying-glass text-dark-3"
      ></i>
    </div>
    <!-- resultados busqueda -->
    <div
      v-show="isOpenResultados"
      class="
        col
        sm:w-95%
        md:w-80
        mt-7
        position-fixed
        z-2
        bg-white
        us:h-max-20
        border-radius-bottom-right-2 border-radius-bottom-left-2
        shadow-2
      "
    >
      <div
        class="position-relative"
        :class="[arrayTabla.length <= 2 ? 'h-20' : 'h-50']"
      >
        <div class="scroll-vertical">
          <div
            class="col hover:bg-white-2 cursor-pointer pv-2 ph-1"
            v-for="(item, index) in arrayTabla"
            :key="index"
            :class="[item.comunidad == '' ? 'bg-white-1' : '']"
          >
            <div class="col w-100%" v-on:click="seleccionaItem(item)">
              <p class="text-primary text-size-2">{{ item.comunidad }}</p>
              <p
                :class="[
                  item.comunidad == ''
                    ? 'text-primary text-size-3 bold'
                    : 'text-dark-2 text-size-2',
                ]"
              >
                Municipio: {{ item.municipio }} -
                <strong>{{ item.codigo_texto }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MunicipioService from "@/services/municipio.service";
let me = null;
export default {
  data() {
    return {
      // arrayTabla: [
      //   { nombre_comunidad: "", nombre_municipio: "Municipio 1" },
      //   { nombre_comunidad: "Chiquitos", nombre_municipio: "Municipio 1" },
      //   { nombre_comunidad: "", nombre_municipio: "Municipio 2" },
      //   { nombre_comunidad: "Yape", nombre_municipio: "Municipio 2" },
      //   { nombre_comunidad: "Aruquipa", nombre_municipio: "Municipio 2" },
      //   { nombre_comunidad: "Armillo", nombre_municipio: "Municipio 1" },
      // ],
      statusBack: false,

      arrayTabla: [],
      txtBuscar: "",
      isOpenResultados: false,
      timeoutSearch: null,
    };
  },
  mounted() {
    me = this;
  },
  computed: {
    // filteredList() {
    //   if (this.txtBuscar) {
    //     return this.arrayTabla.filter((item) => {
    //       return (
    //         this.txtBuscar
    //           .toLowerCase()
    //           .split(" ")
    //           .every((v) => item.nombre_municipio.toLowerCase().includes(v)) ||
    //         this.txtBuscar
    //           .toLowerCase()
    //           .split(" ")
    //           .every((v) => item.nombre_comunidad.toLowerCase().includes(v))
    //       );
    //     });
    //   } else {
    //     return this.arrayTabla;
    //   }
    // },
  },
  methods: {
    hidePopup() {
      this.isOpenResultados = false;
      this.$el.blur();
      console.log("hide popup");
    },
    showPopup() {
      console.log("show popup");
      this.isOpenResultados = true;
      this.$el.focus();
    },
    togglePopup() {
      if (this.isOpenResultados == false) {
        this.$refs.txtBuscar.$el.focus();
      }
    },
    seleccionaItem(item) {
      //si no hay texto entonces sera a nivel bolivia
      var rango = "Bolivia";
      console.log("item search....");
      console.log(item);
      var arrayAux = null;
      if (item != null) {
        this.txtBuscar =
          item.comunidad.length > 0
            ? "Municipio " + item.municipio + " - " + item.comunidad
            : "Municipio " + item.municipio + ": " + item.codigo_texto;
        //determinamos si el item es un municipio o comunidad
        rango = item.comunidad.length > 0 ? "Comunidad" : "Municipio";

        arrayAux = {
          codigo: item.codigo_texto,
          municipio: item.municipio,
          codigo_ine: item.codigo_ine,
          comunidad: item.comunidad,
          rango: rango,
        };
      } else {
        arrayAux = {
          rango: rango, //Municipio-Comunidad
          codigo: null,
          codigo_ine: null,
          comunidad: null,
          municipio: null,
        };
      }
      this.isOpenResultados = false;

      this.$emit("selectItem", arrayAux);
    },
    buscar() {
      me.isOpenResultados = true;
      me.$refs.refBuscar.focus();
      clearTimeout(me.timeoutSearch);
      me.timeoutSearch = setTimeout(function () {
        me.DBgetProductos();
      }, 1000);
    },
    DBgetProductos() {
      // //dialogo de carga
      // gudi.loading.show({
      //   message: "Recuperando datos, espere porfavor...",
      // });
      //realizamos peticion al servicio
      MunicipioService.getMunicipioComunidad({
        search: me.txtBuscar,
      })
        .then((response) => {
          // gudi.loading.clear();
          me.arrayTabla = response.data;
          //realizamos la operacion para integrar item de municipio en las lista
          var municipioAux = "";
          var arrayTablaAux = [];
          me.arrayTabla.forEach((element) => {
            //obtenemos el 1er municipio y lo agregamos a la lista con comunidad vacio
            if (municipioAux != element.municipio) {
              arrayTablaAux.push({
                codigo_texto: element.codigo_texto,
                municipio: element.municipio,
                codigo_ine: "",
                comunidad: "",
              });
              //renombramos nuestro auxiliar para que no se vuelva a agregar el mismo municicpio
              municipioAux = element.municipio;
            }
            //agreamos el item con normalidad
            arrayTablaAux.push({
              codigo_texto: element.codigo_texto,
              municipio: element.municipio,
              comunidad: element.comunidad,
              codigo_ine: element.codigo_ine,
            });
          });
          //ya finalizando asiganmos el valor de nuestro arrayAux al array principal
          me.arrayTabla = arrayTablaAux;
        })
        .catch(function (err) {
          // gudi.loading.clear();
          console.log(err);
        });
    },
    isShowBack() {
      return me.statusBack;
    },
    setShowBack(status) {
      me.statusBack = status;
    },
  },
};
</script>

<style>
</style>
