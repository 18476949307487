// import Vue from 'vue'
import axios from 'axios';
import GLOBAL from '@/utils/global.js';

//configuramos la url base del api
const baseURL = GLOBAL.baseUrlApi();
//recuperamos el usuario actual
const user = JSON.parse(localStorage.getItem('user'));

let apiJson = axios.create({
   baseURL: baseURL,
   headers: {
      Authorization: user ? user.token : '',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
   },
});
// response interceptor
apiJson.interceptors.response.use(
   (response) => {
      //envia respuestas recibidas
      GLOBAL.log(response);
      return response;
   },
   (err) => {
      GLOBAL.log(err);
      if (err.response) {
         //verificamos que el token no haya caducado
         if (err.response.status == 401) {
         }
      } else if (err.request) {
         // client never received a response, or request never left
         alert('No hay respuesta del servidor: \n\n' + err.request);
      } else {
         // anything else
         alert('No hay conexion con el servidor: \n\n' + err);
      }
      //envia respuestas fallidas 500 o 400
      return Promise.reject(err);
   }
);

function sendForm(method, url, dataForm = {}) {
   return apiJson({
      url: url,
      method: method,
      data: dataForm,
      headers: {
         // 'Content-Type': 'application/x-www-form-urlencoded',
         'Content-Type': 'multipart/form-data',
      },
   });
}

function send(method, url, dataForm = {}) {
   return apiJson({
      url: url,
      method: method,
      data: dataForm,
      headers: {
         'Content-Type': 'application/json',
      },
   });
}

export default {
   refresh() {
      apiJson = axios.create({
         baseURL: baseURL,
         headers: {
            Authorization: JSON.parse(localStorage.getItem('user')).token,
         },
      });
   },
   //comunidad=opcional
   getConsolidadoIca({ comunidad = '' }) {
      return send('GET', '/consolidado-ica?comunidad=' + comunidad);
   },
   getIndiceDisponibilidades({ tipo = '', comunidad = '' }) {
      return send('GET', '/indice-disponibilidades?tipo=' + tipo + '&comunidad=' + comunidad);
   },
   getIndiceAccesos({ tipo = '' }) {
      return send('GET', '/indice-accesos/' + tipo);
   },
   getIndiceUsos({ tipo = '' }) {
      return send('GET', '/indice-usos/' + tipo);
   },
   get({ url }) {
      return send('GET', url);
   },
};
