<template>
  <div class="position-relative w-100% h-100%">
    <iframe
      class="position-absolute top-0 left-0 w-100% h-100%"
      :src="url"
      id="contenedor_frame"
    ></iframe>
    <button
      class="
        position-absolute
        left-0
        us:top-50%
        bg-white-1
        shadow-2
        cursor-pointer
        text-primary text-size-4
        border-radius-top-right-2 border-radius-bottom-right-2
        ph-2
        pv-4
      "
      @click="$emit('closePanel')"
    >
      <i class="fa-solid fa-caret-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: ["url"],
};
</script>

<style>
</style>
