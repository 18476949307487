<template>
  <!-- modal actualiza -->
  <div
    class="
      us:bg-modal-transparent
      fade-in
      col
      w-100%
      h-100%
      top-0
      left-0
      position-fixed
      z-3
      align-center
      justify-center
    "
  >
    <div class="col w-95% h-95% bg-white col align-center">
      <div class="row w-100% bg-primary align-center us:shadow-input-search">
        <h3 class="text-white-2 flex-1 ph-2 pv-2">Documentos</h3>
        <i
          class="
            icon-cancel
            bg-primary-2
            hover:bg-primary
            text-size-5 text-white-3
            cursor-pointer
            p-1
          "
          @click="$emit('closeModal')"
        ></i>
      </div>
      <div class="row w-100% p-2 bg-white shadow-2">
        <div
          class="w-fit-content border-radius-5 ph-2 pv-2 cursor-pointer"
          :class="[
            tabSeleccionado == 'Documentos de municipio'
              ? 'text-white bg-primary hover:bg-primary-1'
              : 'text-dark-2 hover:bg-white-2',
          ]"
          @click="
            tabSeleccionado = 'Documentos de municipio';
            nuevoDocumento = {
              foto: null,
            };
          "
        >
          Documentos de municipio
        </div>
        <div
          class="w-fit-content border-radius-5 ph-2 pv-2 cursor-pointer"
          :class="[
            tabSeleccionado == 'Documentos generales'
              ? 'text-white bg-primary hover:bg-primary-1'
              : 'text-dark-2 hover:bg-white-2',
          ]"
          @click="
            tabSeleccionado = 'Documentos generales';
            DBgetDocumentos();
            nuevoDocumento = {
              foto: null,
            };
          "
        >
          Documentos generales
        </div>
      </div>

      <div
        v-if="tabSeleccionado == 'Documentos de municipio'"
        class="row flex-1 w-100%"
      >
        <div class="col-5 col h-100% pt-2 ph-2">
          <input
            class="
              us:holder-dark
              border-radius-1 border-color-dark-3 border-solid-1
              w-100%
              text-dark
              p-2
            "
            placeholder="Buscar municipio por nombre o codigo"
            type="text"
            v-model="txtBuscaMunicipios"
          />

          <div class="position-relative w-100% flex-1">
            <div class="scroll-vertical col align-center pt-3">
              <!-- lista de munciipios -->
              <div class="col w-100%">
                <div
                  class="row w-100%"
                  v-for="(item, index) in filtrarMunicipio"
                  :key="index"
                  v-on:click="
                    itemMunicipio = item;
                    DBgetDocumentos();
                  "
                >
                  <p
                    class="
                      hover:bg-white-2
                      text-dark-1
                      w-100%
                      p-2
                      border-solid-1-bottom border-color-white-2
                      cursor-pointer
                    "
                  >
                    <strong class="text-primary-1">{{ item.codigo }}:</strong>
                    {{ item.municipio }}
                  </p>
                </div>
              </div>
              <!-- end lista de munciipios -->
            </div>
          </div>
        </div>
        <div class="col-7">
          <!-- comunidad seleccionado -->
          <div v-if="itemMunicipio != null" class="col h-100% bg-white-1">
            <div class="row w-100% p-2">
              <div
                class="
                  col
                  w-100%
                  border-radius-2 border-solid-1 border-color-primary-1
                  p-2
                "
              >
                <p class="text-primary bold">
                  Municipio:<span class="text-dark-1 ml-2 bold-min">{{
                    itemMunicipio.municipio
                  }}</span>
                </p>
                <p class="text-primary bold">
                  Codigo de municipio:<span class="text-dark-1 ml-2 bold-min">{{
                    itemMunicipio.codigo
                  }}</span>
                </p>
                <p class="text-primary bold">
                  Departamento:<span class="text-dark-1 ml-2 bold-min">{{
                    itemMunicipio.departamento
                  }}</span>
                </p>
                <p class="text-primary bold">
                  Provincia:<span class="text-dark-1 ml-2 bold-min">{{
                    itemMunicipio.provincia
                  }}</span>
                </p>
              </div>
            </div>
            <div class="row w-100% ph-2">
              <label
                class="
                  row
                  flex-1
                  align-center
                  justify-center
                  border-color-dark-2 border-solid-1 border-radius-2
                "
              >
                <label class="flex-1 text-dark bold text-size-3 pl-2">{{
                  nuevoDocumento.foto != null ? nuevoDocumento.foto.name : "..."
                }}</label>
                <input
                  class="d-none"
                  type="file"
                  id="idArchivoDocumento"
                  ref="refArchivoDocumento"
                  capture="environment"
                  @change="handleImagenDocumento"
                />
                <label
                  class="
                    bg-primary-1
                    border-radius-2
                    p-2
                    cursor-pointer
                    hover:bg-primary
                  "
                  for="idArchivoDocumento"
                >
                  <i class="fas fa-paperclip text-white opacity-7"></i>
                </label>
              </label>

              <button
                :disabled="nuevoDocumento.foto == null"
                class="
                  bg-primary-1
                  border-radius-2
                  p-2
                  text-white
                  ml-1
                  cursor-pointer
                  hover:bg-primary
                "
                @click="DBsubirDocumento()"
              >
                <i class="fas fa-save"></i> Guardar
              </button>
            </div>
            <div class="position-relative w-100% flex-1">
              <div class="scroll-vertical ph-2 pt-2">
                <div
                  class="row w-100% pv-1 hover:bg-white-2 align-center"
                  v-for="(item, index) in arrayDocumentos"
                  :key="index"
                >
                  <div
                    class="
                      col
                      w-10
                      h-10
                      border-radius-50%
                      align-center
                      justify-center
                      bg-primary-1
                      text-white
                    "
                  >
                    {{ item.documento_path.split(".").pop() }}
                  </div>
                  <div
                    class="
                      col
                      flex-1
                      p-2
                      border-solid-1-bottom border-color-white-2
                    "
                  >
                    <p class="text-dark-1 cursor-pointer">
                      {{
                        item.documento +
                        "." +
                        item.documento_path.split(".").pop()
                      }}
                    </p>
                    <p class="text-dark-2 text-size-2">
                      Subido el: {{ item.created_at }}
                    </p>
                  </div>
                  <a
                    :href="
                      GLOBAL.baseUrl(
                        '/public/SDcard/documentosMunicipio/' +
                          item.documento_path
                      )
                    "
                    :download="
                      item.documento + item.documento_path.split('.').pop()
                    "
                    alt="Descargar"
                    class="
                      hover:bg-primary-1 hover:text-white
                      text-primary-1
                      p-2
                      mr-2
                      border-radius-2
                    "
                    ><i class="fas fa-download"></i
                  ></a>
                  <button
                    class="
                      bg-red
                      text-white
                      border-radius-2
                      shadow-2
                      p-2
                      mr-2
                      hover:bg-white hover:text-primary-1
                      cursor-pointer
                    "
                    @click="DBdeleteDocumento(item)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- comunidad sin seleccionar -->
          <div
            v-else
            class="col h-100% bg-white-1 align-center justify-center ph-10"
          >
            <h1>Documentos de municipio</h1>
            <p class="text-center">
              Selecciona un municipio del lado izquierdo del modal para poder
              visualizar los archivos.
            </p>
          </div>
        </div>
      </div>

      <div v-else class="flex-1 col w-100%">
        <div class="row w-100% ph-2 mt-5">
          <label
            class="
              row
              flex-1
              align-center
              justify-center
              border-color-dark-2 border-solid-1 border-radius-2
            "
          >
            <label class="flex-1 text-dark bold text-size-3 pl-2">{{
              nuevoDocumento.foto != null ? nuevoDocumento.foto.name : "..."
            }}</label>
            <input
              class="d-none"
              type="file"
              id="idArchivoDocumento"
              ref="refArchivoDocumento"
              capture="environment"
              @change="handleImagenDocumento"
            />
            <label
              class="
                bg-primary-1
                border-radius-2
                p-2
                cursor-pointer
                hover:bg-primary
              "
              for="idArchivoDocumento"
            >
              <i class="fas fa-paperclip text-white opacity-7"></i>
            </label>
          </label>

          <button
            :disabled="nuevoDocumento.foto == null"
            class="
              bg-primary-1
              border-radius-2
              p-2
              text-white
              ml-1
              cursor-pointer
              hover:bg-primary
            "
            @click="DBsubirDocumento()"
          >
            <i class="fas fa-save"></i> Guardar
          </button>
        </div>
        <div class="position-relative w-100% flex-1">
          <div class="scroll-vertical ph-2 pt-2">
            <div
              class="row w-100% pv-1 hover:bg-white-2 align-center"
              v-for="(item, index) in arrayDocumentosGenerales"
              :key="index"
            >
              <div
                class="
                  col
                  w-10
                  h-10
                  border-radius-50%
                  align-center
                  justify-center
                  bg-primary-1
                  text-white
                "
              >
                {{ item.documento_path.split(".").pop() }}
              </div>
              <div
                class="
                  col
                  flex-1
                  p-2
                  border-solid-1-bottom border-color-white-2
                "
              >
                <p class="text-dark-1 cursor-pointer">
                  {{ item.documento + item.documento_path.split(".").pop() }}
                </p>
                <p class="text-dark-2 text-size-2">
                  Subido el: {{ item.created_at }}
                </p>
              </div>
              <a
                :href="
                  GLOBAL.baseUrl(
                    '/public/SDcard/documentosMunicipio/' + item.documento_path
                  )
                "
                :download="
                  item.documento + '.' + item.documento_path.split('.').pop()
                "
                alt="Descargar"
                class="
                  hover:bg-primary-1 hover:text-white
                  text-primary-1
                  p-2
                  mr-2
                  border-radius-2
                "
                ><i class="fas fa-download"></i
              ></a>
              <button
                class="
                  bg-red
                  text-white
                  border-radius-2
                  shadow-2
                  p-2
                  mr-2
                  hover:bg-white hover:text-primary-1
                  cursor-pointer
                "
                @click="DBdeleteDocumento(item)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentosService from "@/services/documentos.service";

let me = null;
export default {
  data() {
    return {
      tabSeleccionado: "Documentos de municipio",
      arrayMunicipios: [],
      txtBuscaMunicipios: "",
      itemMunicipio: null,
      //docuentos
      arrayDocumentos: [],
      arrayDocumentosGenerales: [],
      nuevoDocumento: {
        foto: null,
      },
    };
  },
  mounted() {
    me = this;
    setTimeout(function () {
      me.DBgetMunicipios();
    }, 200);
  },
  computed: {
    filtrarMunicipio: function () {
      return this.arrayMunicipios.filter((item) => {
        return (
          item.municipio !== null &&
          (item.municipio.toLowerCase().includes(this.txtBuscaMunicipios) ||
            item.codigo.toLowerCase().includes(this.txtBuscaMunicipios))
        );
      });
    },
  },
  methods: {
    handleImagenDocumento() {
      let uploadedFiles = this.$refs["refArchivoDocumento"].files[0];
      me.nuevoDocumento.foto = uploadedFiles;
      console.log(uploadedFiles);
    },
    DBdeleteDocumento(itemDoc) {
      gudi.confirm
        .show({
          title: "Eliminar documento",
          message: "Esta seguro de realizar esta accion?",
          textCancel: "Cancelar",
          textSuccess: "Aceptar",
        })
        .then(function (status) {
          if (status) {
            //dialogo de carga
            gudi.loading.show({
              message: "Eliminando datos, espere porfavor...",
            });
            //realizamos peticion al servicio
            DocumentosService.delete({
              id_documento: itemDoc.id_documento,
            })
              .then((response) => {
                gudi.loading.clear();
                me.DBgetDocumentos();
              })
              .catch(function (error) {
                gudi.loading.clear();
                gudi.alert.show({
                  title: "Error en los datos",
                  message: me.GLOBAL.getErrorHTTP(error),
                });
              });
          }
        });
    },
    DBsubirDocumento() {
      gudi.loading.show({
        message: "Subiendo archivos, espere porfavor...",
      });
      let form = new FormData();

      form.append(
        "codigo_municipio",
        me.tabSeleccionado == "Documentos de municipio"
          ? me.itemMunicipio.codigo
          : 0
      );
      form.append(
        "documento",
        me.nuevoDocumento.foto.name.replace(/\.[^/.]+$/, "")
      );
      form.append("documento_path", me.nuevoDocumento.foto);
      //realizamos peticion al servicio
      DocumentosService.create({ form })
        .then((response) => {
          gudi.loading.clear();
          me.nuevoDocumento = {
            foto: null,
          };
          me.DBgetDocumentos();
        })
        .catch(function (error) {
          gudi.loading.clear();
          gudi.alert.show({
            title: "Error en los datos",
            message: me.GLOBAL.getErrorHTTP(error),
          });
        });
    },
    DBgetDocumentos() {
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando datos, espere porfavor...",
      });

      //realizamos peticion al servicio
      DocumentosService.list({
        codigoMunicipio:
          me.tabSeleccionado == "Documentos de municipio"
            ? me.itemMunicipio.codigo
            : 0,
      })
        .then((response) => {
          gudi.loading.clear();
          if (me.tabSeleccionado == "Documentos de municipio") {
            me.arrayDocumentos = response.data;
          } else {
            me.arrayDocumentosGenerales = response.data;
          }
        })
        .catch(function (error) {
          gudi.loading.clear();
          gudi.alert.show({
            title: "Error en los datos",
            message: me.GLOBAL.getErrorHTTP(error),
          });
        });
    },
    DBgetMunicipios() {
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando datos, espere porfavor...",
      });
      //realizamos peticion al servicio
      DocumentosService.listMunicipios({})
        .then((response) => {
          gudi.loading.clear();
          me.arrayMunicipios = response.data;
        })
        .catch(function (error) {
          gudi.loading.clear();
          gudi.alert.show({
            title: "Error en los datos",
            message: me.GLOBAL.getErrorHTTP(error),
          });
        });
    },
  },
};
</script>

<style>
</style>
