<template>
  <!-- modal actualiza -->
  <div
    class="
      us:bg-modal-transparent
      fade-in
      col
      w-100%
      h-100%
      top-0
      left-0
      position-fixed
      z-3
      align-center
      justify-center
    "
  >
    <div class="col w-95% h-95% bg-white col align-center">
      <div class="row w-100% bg-primary-1 align-center us:shadow-input-search">
        <h3 class="text-white-2 flex-1 ph-2 pv-2">
          Administracion de usuarios
        </h3>
        <i
          class="
            icon-cancel
            bg-primary-2
            hover:bg-primary
            text-size-5 text-white-3
            cursor-pointer
            p-1
          "
          @click="$emit('closeModal')"
        ></i>
      </div>
      <div class="row w-100% p-2 shadow-2 bg-white align-center">
        <div
          class="
            w-fit-content
            border-radius-3
            ph-2
            pv-1
            cursor-pointer
            bg-primary
            text-white
            hover:bg-primary-1
            mr-2
          "
          @click="tabView = 'usuarios';"
        >
          Usuarios
        </div>
        <div
          class="
            w-fit-content
            border-radius-3
            ph-2
            pv-1
            cursor-pointer
            bg-primary
            text-white
            hover:bg-primary-1
          "
          @click="tabView = 'interaccion';"
        >
          Analisis de interaccion
        </div>
      </div>
      <div class="col w-100% flex-1" v-if="tabView == 'usuarios'">
        <div class="row w-100% p-3">
           <div
          class="
            w-fit-content
            border-radius-3
            ph-2
            pv-1
            cursor-pointer
            bg-primary
            text-white
            hover:bg-primary-1
            row
            align-center
            mr-2
            sm:mb-2
          "
          @click="
            modalCrear = true;
            DBgetCiudades();
          "
        >
          + Nuevo usuario
        </div>
        <input
            class="
              us:holder-dark
              border-radius-1 border-color-dark-3 border-solid-1
              sm:w-100%
              md:w-98
              text-dark
              p-2
            "
            placeholder="Buscar municipio por nombre o codigo"
            type="text"
            v-model="textBucar"
            v-on:keyup.enter="buscarRegistro()"
            v-on:keydown="buscarRegistro()"
          />
        </div>

        <!-- lista de registros -->
        <div class="flex-1 position-relative h-100%">
          <div class="scroll-vertical ph-3">
            <div
              v-for="(item, index) in arrayTabla.data"
              :key="index"
              class="
                row
                w-100%
                border-solid-1-bottom border-color-dark-3
                hover:bg-white-2
              "
            >
              <img
                v-if="item.avatar != null"
                class="bg-white-3 border-radius-50% w-12 h-12 object-cover"
                :src="
                  GLOBAL.baseUrl('/public/SDcard/usuario_lite/' + item.avatar)
                "
                alt=""
                loading="lazy"
              />
              <img
                v-else
                class="bg-white-3 border-radius-50% w-12 h-12 object-cover"
                src="@/assets/no_image.jpg"
                alt=""
                loading="lazy"
              />
              <div class="row flex-1">
                <div class="sm:col-12 md:col-5 col justify-center">
                  <h4
                    v-if="item.username != null && item.username.length > 0"
                    class="text-primary-1"
                  >
                    {{ item.username }}
                  </h4>
                  <i v-else class="text-dark-3">Nombre pendiente</i>
                  <p class="text-dark-1 text-size-3">{{ item.email }}</p>
                </div>
                <div
                  class="
                    sm:col-12
                    md:col-2
                    col
                    w-100%
                    justify-center
                    text-size-2 text-dark-1
                  "
                >
                  <p>{{ item.login_at }}</p>
                  <i class="text-dark-2">Ultimo ingreso</i>
                </div>
                <div class="sm:col-12 md:col-3 row align-center text-size-2">
                  Estado:
                  <strong class="ml-1 text-primary-1">{{ item.estado }}</strong>
                </div>
                <div
                  class="sm:col-12 md:col-2 row align-center justify-end pr-3"
                >
                  <button
                    class="
                      bg-green
                      text-white
                      border-radius-2
                      shadow-2
                      p-2
                      mr-2
                      hover:bg-white hover:text-primary-1
                      cursor-pointer
                    "
                    @click="
                      form = item;
                      form.password = '';
                      DBgetCiudades();
                      modalEditar = true;
                    "
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="
                      bg-red
                      text-white
                      border-radius-2
                      shadow-2
                      p-2
                      hover:bg-white hover:text-primary-1
                      cursor-pointer
                    "
                    @click="
                      form = item;
                      DBdeleteUser();
                    "
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>

            <!-- //paginacion -->
            <div class="row w-100% p-4 justify-center">
              <button
                :disabled="arrayTabla.current_page == 1"
                class="
                  bg-white
                  text-primary-1
                  border-radius-2
                  shadow-2
                  p-2
                  ml-1
                  cursor-pointer
                "
                @click="
                  pagina = arrayTabla.current_page - 1;
                  DBgetUsers();
                "
              >
                <i class="fas fa-angle-left"></i>
              </button>

              <button
                class="border-radius-2 shadow-2 p-2 cursor-pointer ml-1"
                v-for="index in arrayTabla.last_page"
                :key="index"
                @click="
                  pagina = index;
                  DBgetUsers();
                "
                :class="[
                  index == pagina
                    ? 'bg-primary-1 text-white'
                    : 'bg-white text-primary-1',
                ]"
              >
                {{ index }}
              </button>

              <button
                :disabled="pagina == arrayTabla.last_page"
                class="
                  bg-white
                  text-primary-1
                  border-radius-2
                  shadow-2
                  p-2
                  ml-1
                  hover:bg-white hover:text-primary-1
                  cursor-pointer
                "
                @click="
                  pagina = arrayTabla.current_page + 1;
                  DBgetUsers();
                "
              >
                <i class="fas fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- end lista de registros -->
      </div>

      <div class="col w-100% flex-1" v-if="tabView == 'interaccion'">
        <div class="flex-1 position-relative h-100%">
          <div class="scroll-vertical ph-3">
        <!-- <div class="row w-100% h-100% p-3"> -->
          <!-- lista de estadisticas -->
          <UserReport></UserReport>
          <!-- end lista de estadisticas -->
          </div>
        </div>
      </div>

    <!-- modal crear -->
    <div
      v-if="modalCrear"
      class="
        us:bg-modal-transparent
        fade-in
        col
        w-100%
        h-100%
        top-0
        left-0
        position-fixed
        z-3
        align-center
        justify-center
      "
    >
      <div
        class="
          col
          sm:w-95%
          md:w-35%
          sm:h-85%
          md:h-55%
          bg-white
          border-radius-2
          col
          p-2
        "
      >
        <h3 class="bold text-primary w-100%">Crear usuario</h3>
        <label class="text-dark-1 text-size-2 mt-3 mb-1"
          >Nombre <span class="text-dark-2 text-size-1">(Opcional)</span></label
        >
        <input
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          placeholder="..."
          type="text"
          v-model="form.username"
        />
        <label class="text-dark-1 text-size-2 mt-3 mb-1"
          >Fotografia
          <span class="text-dark-2 text-size-1">(Opcional)</span></label
        >
        <input
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          placeholder="Buscar municipio por nombre o codigo"
          type="file"
          accept="image/*,.png,.jpeg,.jpg"
          ref="refImagenUsuario"
          @change="handleImagenUsuario"
        />
        <label class="text-dark-1 text-size-2 mt-3 mb-1">Ciudad*</label>
        <select
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          v-model="form.id_ciudad"
        >
          <option class="text-dark-2" disabled value="" :selected="true">
            -- Elige tu ciudad --
          </option>
          <option
            class="text-dark"
            v-for="(item, index) in arrayCiudades"
            :key="index"
            :value="item.id_ciudad"
            :selected="true"
          >
            {{ item.ciudad }}
          </option>
        </select>
        <label class="text-dark-1 text-size-2 mt-3 mb-1">Correo*</label>
        <input
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          placeholder="user@test.com"
          type="text"
          v-model="form.email"
        />
        <label class="text-dark-1 text-size-2 mt-3 mb-1">Contraseña*</label>
        <input
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          placeholder="Contraseña mayor a 4 caracteres"
          type="text"
          v-model="form.password"
        />
        <div
          v-if="message_error.length > 0"
          class="
            row
            w-100%
            text-red
            border-radius-2
            mt-2
            p-2
            border-solid-1 border-color-red
          "
        >
          {{ message_error }}
        </div>
        <div class="flex-1"></div>
        <div class="row w-100% justify-end">
          <div
            class="
              w-fit-content
              border-radius-3
              mt-3
              ph-2
              pv-2
              cursor-pointer
              bg-dark-3
              text-dark
              hover:bg-primary-1
              mr-2
            "
            @click="modalCrear = false"
          >
            Cancelar
          </div>
          <div
            class="
              w-fit-content
              border-radius-3
              mt-3
              ph-2
              pv-2
              cursor-pointer
              bg-primary
              text-white
              hover:bg-primary-1
            "
            @click="DBcreateUser()"
          >
            Guardar registro
          </div>
        </div>
      </div>
    </div>

    <!-- modal editar -->
    <div
      v-if="modalEditar"
      class="
        us:bg-modal-transparent
        fade-in
        col
        w-100%
        h-100%
        top-0
        left-0
        position-fixed
        z-3
        align-center
        justify-center
      "
    >
      <div
        class="
          col
          sm:w-95%
          md:w-35%
          sm:h-85%
          md:h-55%
          bg-white
          border-radius-2
          col
          p-2
        "
      >
        <h3 class="bold text-primary w-100%">Editar usuario</h3>
        <label class="text-dark-1 text-size-2 mt-3 mb-1"
          >Nombre <span class="text-dark-2 text-size-1">(Opcional)</span></label
        >
        <input
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          placeholder="..."
          type="text"
          v-model="form.username"
        />
        <label class="text-dark-1 text-size-2 mt-3 mb-1"
          >Fotografia
          <span class="text-dark-2 text-size-1">(Opcional)</span></label
        >
        <input
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          placeholder="Buscar municipio por nombre o codigo"
          type="file"
          accept="image/*,.png,.jpeg,.jpg"
          ref="refImagenUsuario"
          @change="handleImagenUsuario"
        />
        <label class="text-dark-1 text-size-2 mt-3 mb-1">Ciudad*</label>
        <select
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          v-model="form.ciudad_id"
          ref="refEditarIdCiudad"
        >
          <option
            class="text-dark"
            v-for="(item, index) in arrayCiudades"
            :key="index"
            :value="item.id_ciudad"
          >
            {{ item.ciudad }}
          </option>
        </select>
        <label class="text-dark-1 text-size-2 mt-3 mb-1">Correo*</label>
        <input
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          placeholder="user@test.com"
          type="text"
          v-model="form.email"
        />
        <label class="text-dark-1 text-size-2 mt-3 mb-1">Contraseña*</label>
        <input
          class="
            us:holder-dark
            border-radius-1 border-color-dark-3 border-solid-1
            sm:w-100%
            md:w-100%
            text-dark
            p-2
          "
          placeholder="Contraseña mayor a 4 caracteres"
          type="text"
          v-model="form.password"
        />
        <div
          v-if="message_error.length > 0"
          class="
            row
            w-100%
            text-red
            border-radius-2
            mt-2
            p-2
            border-solid-1 border-color-red
          "
        >
          {{ message_error }}
        </div>
        <div class="flex-1"></div>
        <div class="row w-100% justify-end">
          <div
            class="
              w-fit-content
              border-radius-3
              mt-3
              ph-2
              pv-2
              cursor-pointer
              bg-dark-3
              text-dark
              hover:bg-primary-1
              mr-2
            "
            @click="modalEditar = false"
          >
            Cancelar
          </div>
          <div
            class="
              w-fit-content
              border-radius-3
              mt-3
              ph-2
              pv-2
              cursor-pointer
              bg-primary
              text-white
              hover:bg-primary-1
            "
            @click="DBupdateUser()"
          >
            Guardar registro
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import CiudadesService from "@/services/ciudades.service";
import UsuariosService from "@/services/usuarios.service";
import UserReport from "./UserReport/index.vue"
let me = null;
export default {
  components: {UserReport},
  data() {
    return {
      form: {
        id_usuario: "",
        username: "",
        email: "",
        password: "",
        id_ciudad: "",
        avatar: "",
      },
      message_error: "",
      modalCrear: false,
      modalEditar: false,
      arrayCiudades: [],
      arrayTabla: [],
      timeoutSearch: null,
      textBucar: "",
      pagina: 1,

      //vistas
      tabView : 'usuarios'
    };
  },
  mounted() {
    me = this;
    setTimeout(function () {
      me.DBgetUsers();
    }, 200);
  },
  methods: {
    buscarRegistro() {
      clearTimeout(me.timeoutSearch);
      me.timeoutSearch = setTimeout(function () {
        me.DBgetUsers();
      }, 700);
    },
    DBdeleteUser() {
      gudi.confirm
        .show({
          title: "Eliminar usuario",
          message: "Esta seguro de realizar esta accion?",
          textCancel: "Cancelar",
          textSuccess: "Aceptar",
        })
        .then(function (status) {
          if (status) {
            //dialogo de carga
            gudi.loading.show({
              message: "Eliminando datos, espere porfavor...",
            });
            //realizamos peticion al servicio
            UsuariosService.delete({ id_usuario: me.form.id_usuario })
              .then((response) => {
                gudi.loading.clear();
                me.DBgetUsers();
              })
              .catch(function (error) {
                gudi.loading.clear();
                gudi.alert.show({
                  title: "Error en los datos",
                  message: me.GLOBAL.getErrorHTTP(error),
                });
              });
          }
        });
    },
    DBgetUsers() {
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando datos, espere porfavor...",
      });
      //realizamos peticion al servicio
      UsuariosService.list({
        paginacion: 20,
        pagina: me.pagina,
        search: me.textBucar,
      })
        .then((response) => {
          gudi.loading.clear();
          me.arrayTabla = response.data;
        })
        .catch(function (error) {
          gudi.loading.clear();
          gudi.alert.show({
            title: "Error en los datos",
            message: me.GLOBAL.getErrorHTTP(error),
          });
        });
    },
    DBcreateUser() {
      if (this.form.email.length <= 0) {
        this.message_error = "Campo correo vacio.";
        return;
      }
      if (this.form.password.length <= 4) {
        this.message_error = "Campo contraseña debe ser mayor a 4 caracteres.";
        return;
      }

      if (this.form.id_ciudad.length <= 0) {
        this.message_error = "Debe seleccionar tu ciudad.";
        return;
      }

      gudi.loading.show({
        message: "Guardando usuario...",
      });
      let form = new FormData();

      form.append("email", me.form.email);
      form.append("password", me.form.password);
      form.append("id_ciudad", me.form.id_ciudad);
      me.form.username != "" ? form.append("username", me.form.username) : null;
      me.form.avatar != "" ? form.append("avatar", me.form.avatar) : null;

      UsuariosService.create({
        form: form,
      })
        .then((response) => {
          gudi.loading.clear();
          me.modalCrear = false;
          me.form = {
            id_usuario: "",
            username: "",
            email: "",
            password: "",
            id_ciudad: "",
            avatar: "",
          };
          me.DBgetUsers();
        })
        .catch(function (err) {
          gudi.loading.clear();
          me.GLOBAL.log(err);
        });
    },
    DBupdateUser() {
      if (this.form.email.length <= 0) {
        this.message_error = "Campo correo vacio.";
        return;
      }
      if (this.form.password.length <= 4) {
        this.message_error = "Campo contraseña debe ser mayor a 4 caracteres.";
        return;
      }

      if (me.form.ciudad_id.length <= 0) {
        this.message_error = "Debe seleccionar tu ciudad.";
        return;
      }

      gudi.loading.show({
        message: "Guardando usuario...",
      });
      let form = new FormData();

      form.append("id_usuario", me.form.id_usuario);
      form.append("email", me.form.email);
      form.append("password", me.form.password);
      form.append("id_ciudad", me.form.ciudad_id);
      me.form.username != "" ? form.append("username", me.form.username) : null;
      me.form.avatar != "" ? form.append("avatar", me.form.avatar) : null;

      UsuariosService.update({
        form: form,
      })
        .then((response) => {
          gudi.loading.clear();
          me.modalEditar = false;
          me.form = {
            id_usuario: "",
            username: "",
            email: "",
            password: "",
            id_ciudad: "",
            avatar: "",
          };
          me.DBgetUsers();
        })
        .catch(function (err) {
          gudi.loading.clear();
          me.GLOBAL.log(err);
        });
    },
    handleImagenUsuario() {
      let uploadedFiles = this.$refs["refImagenUsuario"].files[0];
      me.form.avatar = uploadedFiles;
    },
    DBgetCiudades() {
      console.log(me.form);
      //dialogo de carga
      gudi.loading.show({
        message: "Recuperando datos, espere porfavor...",
      });
      //realizamos peticion al servicio
      CiudadesService.list({})
        .then((response) => {
          gudi.loading.clear();
          me.arrayCiudades = response.data;
        })
        .catch(function (error) {
          gudi.loading.clear();
          gudi.alert.show({
            title: "Error en los datos",
            message: me.GLOBAL.getErrorHTTP(error),
          });
        });
    },
  },
};
</script>

<style>
</style>
